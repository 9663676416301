const LANGS = {
    en: {
        name: 'English',
        flag: 'fi fi-gb',
    },
    es: {
        name: 'Spanish',
        flag: 'fi fi-es',
    },
    cn: {
        name: 'Chinese',
        flag: 'fi fi-cn',
    },
    fr: {
        name: 'French',
        flag: 'fi fi-fr',
    },
    it: {
        name: 'Italian',
        flag: 'fi fi-it',
    },
}

export function langName(lang) {
    return LANGS[lang]?.name
}

export function langFlag(lang) {
    return LANGS[lang]?.flag
}

export function defaultLangs() {
    return Object.keys(LANGS)
}
