import isNil from 'lodash.isnil'
import isEmpty from 'lodash.isempty'
import cloneDeep from 'lodash.clonedeep'

export const isModel = function (value) {
    if (value instanceof Object) {
        return (
            !isNil(value) &&
            !isEmpty(value) &&
            value.pk !== '' &&
            value.pk !== 0
        )
    }
    return false
}

export const isSameModel = function (value1, value2) {
    if (isNil(value1) && isNil(value2)) {
        return true
    }
    if (isModel(value1) && isModel(value2)) {
        return value1.pk === value2.pk
    }
    return false
}

export const pkToId = function (value) {
    if (value && value?.pk) {
        const result = cloneDeep(value)
        result.id = result.pk
        delete result.pk
        return result
    }
    return value
}
