export default [
    {
        name: 'AssociationList',
        path: 'associations',
        component: () => import('./views/AssociationList.vue'),
        meta: {
            title: 'Associations',
            perms: 'clients',
            icon: 'fa-solid fa-book',
        },
    },
    {
        name: 'AssociationCreate',
        path: 'associations/create',
        component: () => import('./views/AssociationForm.vue'),
    },
    {
        name: 'AssociationUpdate',
        path: 'associations/:association',
        component: () => import('./views/AssociationForm.vue'),
    },
    {
        name: 'ClientRegistrationList',
        path: 'clients/registrations',
        component: () => import('./views/ClientRegistrationList.vue'),
        meta: {
            title: 'Clients Registrations',
            perms: 'clients',
            icon: 'fa-solid fa-clipboard-list',
        },
    },
    {
        name: 'ClientRegistrationDetails',
        path: 'clients/registrations/:reg',
        component: () => import('./views/ClientRegistrationDetails.vue'),
    },
    {
        name: 'ClientList',
        path: 'clients',
        component: () => import('./views/ClientList.vue'),
        meta: {
            title: 'Clients',
            perms: 'clients',
            icon: 'fa-solid fa-briefcase',
        },
    },
    {
        name: 'ClientCreate',
        path: 'clients/create',
        component: () => import('./views/ClientForm.vue'),
    },
    {
        name: 'ClientUpdate',
        path: 'clients/:client',
        component: () => import('./views/ClientForm.vue'),
    },
]
