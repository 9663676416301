export default [
    {
        name: 'CallList',
        path: 'calls',
        component: () => import('./views/CallList.vue'),
        meta: {
            title: 'Calls',
            perms: 'leads',
        },
    },
]
