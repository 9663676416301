<template>
    <div
        class="field"
        :class="fieldClasses"
    >
        <label
            v-if="label"
            class="label is-small"
        >
            {{ labelText }}
        </label>

        <div
            class="control"
            :class="controlClasses"
        >
            <input
                v-bind="$attrs"
                ref="input"
                class="input is-small is-fullwidth"
                :class="inputClasses"
                :type="inputType"
                :autocomplete="autocomplete"
                :disabled="readonly || undefined"
                :value="modelValue"
                @input="onInput"
            />
            <span
                v-if="leftIcon"
                class="icon is-left is-small"
            >
                <i :class="leftIcon"></i>
            </span>
            <span
                v-if="password"
                class="icon is-right is-small hide-reveal-icon"
                @click.capture="onPasswordHideRevealIconClicked"
            >
                <i
                    v-if="showPassword"
                    class="fas fa-eye-slash"
                ></i>
                <i
                    v-else
                    class="fas fa-eye"
                ></i>
            </span>
            <span
                v-else-if="rightIcon"
                class="icon is-right is-small"
            >
                <i :class="rightIcon"></i>
            </span>
        </div>
        <p
            v-for="error in errors"
            :key="error"
            class="help is-danger"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'
import InputIconsMixin from './InputIconsMixin'

export default {
    name: 'T3TextField',
    extends: Field,
    mixins: [InputIconsMixin],
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        password: {
            type: Boolean,
            default: false,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
        maxLength: {
            type: Number,
            default: undefined,
        },
    },
    emits: ['update:modelValue'],
    data: () => ({
        showPassword: false,
    }),
    computed: {
        inputType() {
            if (this.password && !this.showPassword) {
                return 'password'
            }
            return 'text'
        },
        fieldClasses() {
            if (this.expanded) {
                return 'is-fullwidth'
            }
            return undefined
        },
        controlClasses() {
            let classes = []
            if (this.expanded) {
                classes.push('is-expanded')
            }
            if (this.leftIcon) {
                classes.push('has-icons-left')
            }
            if (this.rightIcon || this.password) {
                classes.push('has-icons-right')
            }
            return classes
        },
        autocomplete() {
            if (this.password) {
                return 'new-password'
            }
            return undefined
        },
    },
    methods: {
        onInput(event) {
            let val = event.target.value
            if (this.maxLength !== undefined) {
                if (val.length >= this.maxLength) {
                    val = val.substr(0, this.maxLength)
                }
            }
            event.target.value = val
            this.$emit('update:modelValue', val)
        },
        onPasswordHideRevealIconClicked() {
            this.showPassword = !this.showPassword
        },
        focus() {
            this.$refs.input.focus()
        },
    },
}
</script>

<style scoped>
.hide-reveal-icon {
    pointer-events: initial !important;
}
.is-fullwidth {
    width: 100% !important;
}
</style>
