<template>
    <div class="columns">
        <div class="column">
            <form
                ref="fileform"
                enctype="multipart/form-data"
                novalidate
            >
                <div
                    class="file is-small is-fullwidth has-name"
                    :class="{ 'has-name': hasFilename }"
                >
                    <label class="file-label">
                        <input
                            class="file-input"
                            type="file"
                            name="resume"
                            @change="onFileChange"
                        />
                        <span class="file-cta">
                            <span class="file-icon">
                                <i class="fa fa-upload"></i>
                            </span>
                            <span class="file-label">Choose</span>
                        </span>
                        <span
                            v-if="hasFilename"
                            class="file-name"
                        >
                            {{ l_filename }}
                        </span>
                    </label>
                </div>
            </form>
        </div>

        <div
            v-if="showCategories"
            class="column is-narrow"
        >
            <T3ChoicesField
                v-model="l_category"
                choices="DocumentCategory"
                :ignore="ignoreCategories"
            />
        </div>

        <div
            v-if="showUploadButton"
            class="column is-narrow"
        >
            <T3Button
                label="Upload"
                role="primary"
                :loading="l_uploading"
                :disabled="!canUpload"
                @clicked="onUploadButtonClicked"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        filename: {
            type: String,
            default: '',
        },
        showCategories: {
            type: Boolean,
            default: true,
        },
        showUploadButton: {
            type: Boolean,
            default: true,
        },
        documentType: {
            type: String,
            required: true,
        },
        documentTypeId: {
            type: [Number, String],
            default: undefined,
        },
        documentUploadEndpoint: {
            type: String,
            default: '/t3/documents/upload',
        },
        ignoreCategories: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['documentUploaded'],
    data: () => ({
        l_file: null,
        l_filename: '',
        l_category: '0',
        l_uploading: false,
    }),
    computed: {
        hasFilename() {
            return this.l_filename !== ''
        },
        canUpload() {
            return !this.$_.isNil(this.l_file)
        },
    },
    mounted() {
        this.l_filename = this.filename
    },
    methods: {
        onFileChange(event) {
            this.l_file = event.target.files[0]
            this.l_filename = this.l_file.name
        },
        onUploadButtonClicked() {
            if (!this.$_.isNil(this.l_file)) {
                const form_data = new FormData()
                form_data.append('document_type', this.documentType)
                form_data.append('document_type_id', this.documentTypeId)
                form_data.append('category', this.l_category)
                form_data.append('file', this.l_file, this.l_filename)
                this.l_uploading = true
                this.$request(this.documentUploadEndpoint, form_data)
                    .then(() => {
                        const filename_splits = this.l_filename.split('.')
                        this.$emit('documentUploaded', {
                            category: this.choicesLabel(
                                'DocumentCategory',
                                this.l_category
                            ),
                            name: this.l_filename,
                            extension:
                                filename_splits.length > 0
                                    ? filename_splits[
                                          filename_splits.length - 1
                                      ]
                                    : '',
                        })
                        this.clear()
                    })
                    .finally(() => {
                        this.l_uploading = false
                    })
            }
        },
        clear() {
            this.l_file = null
            this.l_filename = this.filename
            this.$refs.fileform.reset()
        },
    },
}
</script>

<style scoped>
.file {
    max-width: 350px !important;
}
</style>
