export default [
    {
        name: 'UserList',
        path: 'users',
        component: () => import('./views/UserList.vue'),
        meta: {
            title: 'Users',
            perms: 'auth',
            icon: 'fa-solid fa-users',
        },
    },
    {
        name: 'UserUpdate',
        path: 'users/:user',
        component: () => import('./views/UserUpdate.vue'),
    },
    {
        name: 'ChangePassword',
        path: 'users/my/password/change',
        component: () => import('./views/ChangePassword.vue'),
    },
]
