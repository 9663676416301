export default [
    {
        name: 'HotelReviewList',
        path: 'reviews/hotels',
        component: () => import('./views/HotelReviewList.vue'),
        meta: {
            title: 'Hotel Reviews',
            perms: 'reviews',
            icon: 'fa-solid fa-ranking-star',
        },
    },
]
