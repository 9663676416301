<template>
    <AutocompleteField
        v-model="$location"
        :label="label"
        right-icon="fa-solid fa-map-pin"
        index="locations_location"
        :errors="errors"
        :filters="filters"
        i18n
    >
        <template #extra="{ item }">
            <div class="extra">
                <span
                    v-if="item.division_name"
                    class="tag is-black mr-2"
                >
                    {{ item.division_name }}, {{ item.country_name }}
                </span>

                <span
                    v-else
                    class="tag is-black mr-2"
                >
                    {{ item.country_name }}
                </span>

                <span
                    v-if="item.country_id"
                    :class="`fi fi-${item.country_id.toLowerCase()} mr-3`"
                >
                </span>
            </div>
        </template>

        <template #item="{ item }">
            <div class="columns item-columns p-2">
                <div class="column is-narrow is-vertical-center">
                    <span>{{ item.name }}</span>
                </div>
                <div class="column"></div>
                <div class="column is-narrow is-vertical-center pr-0 mr-0">
                    <span
                        v-if="item.division_name"
                        class="tag is-black"
                    >
                        {{ item.division_name }}, {{ item.country_name }}
                    </span>

                    <span
                        v-else
                        class="tag is-black"
                    >
                        {{ item.country_name }}
                    </span>
                </div>
                <div class="column is-narrow is-vertical-center">
                    <span
                        v-if="item.country_id"
                        :class="`fi fi-${item.country_id.toLowerCase()}`"
                    >
                    </span>
                </div>
            </div>
        </template>
    </AutocompleteField>
</template>

<script>
import AutocompleteField from './AutocompleteField.vue'

export default {
    name: 'T3LocationAutocompleteField',
    components: {
        AutocompleteField,
    },
    props: {
        modelValue: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            default: 'Location',
        },
        errors: {
            type: Array,
            default: () => [],
        },
        countryId: {
            type: String,
            default: '',
        },
        divisionId: {
            type: Number,
            default: 0,
        },
    },
    emits: ['update:modelValue'],
    computed: {
        $location: {
            get() {
                return this.modelValue
            },
            set(newval) {
                this.$emit('update:modelValue', newval)
            },
        },
        filters() {
            let result = {}

            if (this.countryId) {
                result.country_id = this.countryId
            }

            if (this.divisionId) {
                result.division_id = this.divisionId
            }

            return result
        },
    },
}
</script>

<style scoped>
.extra {
    margin-top: 2px;
}
</style>
