export default [
    {
        name: 'EmailAccountList',
        path: 'crm/accounts',
        component: () => import('./views/EmailAccountList.vue'),
        meta: {
            title: 'Email Accounts',
            perms: 'crm',
            icon: 'fa-solid fa-at',
        },
    },
    {
        name: 'EmailAccountCreate',
        path: 'crm/accounts/create',
        component: () => import('./views/EmailAccountForm.vue'),
    },
    {
        name: 'EmailAccountUpdate',
        path: 'crm/accounts/:account',
        component: () => import('./views/EmailAccountForm.vue'),
    },
]
