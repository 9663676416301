<template>
    <T3FormPage
        :title="title"
        :disable-save="disableSave"
        :hide-save="hideSave"
        :hide-save-and-continue="hideSave"
        @save="onSave"
        @save-and-continue="onSaveAndContinue"
    >
        <template #actions>
            <slot name="actions"></slot>
        </template>
        <slot></slot>
    </T3FormPage>
</template>

<script>
export default {
    name: 'CRUDFormPage',
    props: {
        pkField: {
            type: String,
            default: 'pk',
        },
        form: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            required: true,
        },
        routePkParam: {
            type: String,
            required: true,
        },
        modelName: {
            type: String,
            required: true,
        },
        endpoint: {
            type: String,
            required: true,
        },
        disableSave: {
            type: Boolean,
            default: false,
        },
        hideSave: {
            type: Boolean,
            default: false,
        },
        successCreateMessage: {
            type: String,
            default: undefined,
        },
        successUpdateMessage: {
            type: String,
            default: undefined,
        },
        beforeSubmit: {
            type: Function,
            default: (form) => form,
        },
    },
    emits: ['update:form', 'update:errors'],
    computed: {
        ID() {
            return this.$route.params[this.routePkParam]
        },
        title() {
            return this.ID
                ? `Edit ${this.modelName}`
                : `Create ${this.modelName}`
        },
        createEndpoint() {
            if (this.pkField === 'pk') {
                return `${this.endpoint}/create`
            }
            return this.endpoint
        },
        detailEndpoint() {
            return `${this.endpoint}/${this.ID}`
        },
        updateEndpoint() {
            if (this.pkField === 'pk') {
                return `${this.endpoint}/${this.ID}/edit`
            }
            return `${this.endpoint}/${this.ID}`
        },
        url() {
            if (this.ID) {
                return this.updateEndpoint
            }
            return this.createEndpoint
        },
        cleanedModelName() {
            return this.modelName.replace(/ /g, '')
        },
    },
    async created() {
        if (this.ID) {
            try {
                let form = undefined
                if (this.pkField === 'pk') {
                    form = await this.$request(this.detailEndpoint)
                } else {
                    form = await this.$rest.get(this.updateEndpoint)
                }
                this.$emit('update:form', form)
            } catch (error) {
                this.msgError(error.reason)
            }
        }
    },
    methods: {
        async save() {
            try {
                var result = undefined
                var message = undefined

                if (this.pkField === 'pk') {
                    result = await this.$request(
                        this.url,
                        this.beforeSubmit(this.form)
                    )
                } else if (this.ID) {
                    result = await this.$rest.put(
                        this.updateEndpoint,
                        this.beforeSubmit(this.form)
                    )

                    if (this.successCreateMessage) {
                        message = this.successUpdateMessage
                    }
                } else {
                    result = await this.$rest.post(
                        this.createEndpoint,
                        this.beforeSubmit(this.form)
                    )
                    message = this.successCreateMessage
                }

                if (message) {
                    this.msgSuccess(message)
                }

                return result
            } catch (error) {
                console.error(error)
                if (error.code === 1000) {
                    this.$emit('update:errors', error.body)
                } else {
                    this.$emit('update:errors', error)
                }
                return { pk: null }
            }
        },
        async onSave() {
            const result = await this.save()
            if (result[this.pkField]) {
                this.$router.back()
            }
        },
        async onSaveAndContinue() {
            const result = await this.save()
            if (result[this.pkField] && !this.ID) {
                const name = `${this.cleanedModelName}Update`
                const params = {}
                params[this.routePkParam] = result[this.pkField]

                this.$router.replace({ name, params })
            }
        },
    },
}
</script>
