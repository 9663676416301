import ClosableMessage from './ClosableMessage.vue'
import CRUDDetailsPage from './CRUDDetailsPage.vue'
import CRUDFormPage from './CRUDFormPage.vue'
import CRUDListPage from './CRUDListPage.vue'
import FormPage from './FormPage.vue'
import FormSection from './FormSection.vue'
import GroupBox from './GroupBox.vue'
import ModalWindow from './ModalWindow.vue'
import Page from './Page.vue'
import TabPage from './TabPage.vue'
import TabsComponent from './TabsComponent.vue'

export default function (app) {
    app.component('T3ClosableMessage', ClosableMessage)
    app.component('T3CRUDDetailsPage', CRUDDetailsPage)
    app.component('T3CRUDFormPage', CRUDFormPage)
    app.component('T3CRUDListPage', CRUDListPage)
    app.component('T3FormPage', FormPage)
    app.component('T3FormSection', FormSection)
    app.component('T3GroupBox', GroupBox)
    app.component('T3Modal', ModalWindow)
    app.component('T3Page', Page)
    app.component('T3TabPage', TabPage)
    app.component('T3Tabs', TabsComponent)
}
