<template>
    <div class="flex gap-2">
        <div
            v-for="attachment in modelValue"
            :key="attachment.uid"
        >
            <CRMEmailAttachmentsItem :model-value="attachment" />
        </div>
    </div>
</template>

<script>
import CRMEmailAttachmentsItem from './CRMEmailAttachmentsItem.vue'

export default {
    components: {
        CRMEmailAttachmentsItem,
    },
    props: {
        modelValue: {
            type: Array,
            default: () => [],
        },
    },
}
</script>
