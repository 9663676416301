export default [
    {
        name: 'DayProduction',
        path: 'reports/production/day',
        component: () => import('./views/DayProduction.vue'),
        meta: {
            title: 'Day Production',
            perms: 'internal_reports',
            icon: 'fa-solid fa-chart-pie',
        },
    },
    {
        name: 'DailyProduction',
        path: 'reports/production/daily',
        component: () => import('./views/DailyProduction.vue'),
        meta: {
            title: 'Daily Production',
            perms: 'internal_reports',
            icon: 'fa-solid fa-table',
        },
    },
    {
        name: 'DailyFinances',
        path: 'reports/finances/daily',
        component: () => import('./views/DailyFinances.vue'),
        meta: {
            title: 'Daily Finances',
            perms: 'internal_reports',
            icon: 'fa-solid fa-table',
        },
    },
    {
        name: 'MonthlyInvoicing',
        path: 'reports/finances/invoicing/monthly',
        component: () => import('./views/MonthlyInvoicing.vue'),
        meta: {
            title: "KPI's",
            perms: 'public_reports',
            icon: 'fa-solid fa-table',
        },
    },
    {
        name: 'MonthlyAgencies',
        path: 'reports/agencies/monthly',
        component: () => import('./views/MonthlyAgencies.vue'),
        meta: {
            title: 'Monthly Agencies',
            perms: 'internal_reports',
            icon: 'fa-solid fa-table',
        },
    },
    {
        name: 'MonthlyHistoricInvoicing',
        path: 'reports/finances/invoicing/monthly_historic',
        component: () => import('./views/MonthlyHistoricInvoicing.vue'),
        meta: {
            title: 'Monthly Historic Invoicing',
            perms: 'public_reports',
            icon: 'fa-solid fa-table',
        },
    },
    {
        name: 'MappingsProduction',
        path: 'reports/production/mappings',
        component: () => import('./views/MappingsProduction.vue'),
        meta: {
            title: 'Mappings Production',
            perms: 'internal_reports',
            icon: 'fa-solid fa-signs-post',
        },
    },
    {
        name: 'MonthlyBalanceReport',
        path: 'reports/finances/balance',
        component: () => import('./views/MonthlyBalanceReport.vue'),
        meta: {
            title: 'Monthly Balance Report',
            perms: 'internal_reports',
            icon: 'fa-solid fa-chart-pie',
        },
    },
]
