import { useAuthStore } from '@/stores/auth'
import { mapState } from 'pinia'

export default {
    data: () => ({
        heartbeatStartedAt: null,
        inactivityTimeout: null,
    }),
    computed: {
        ...mapState(useAuthStore, ['token']),
    },
    methods: {
        logHeartbeat(startTime, endTime, useBeacon = false) {
            if (!this.token) {
                return
            }

            const url = '/hr/employees/heartbeats'

            const data = {
                started_at_ts: startTime,
                finished_at_ts: endTime,
            }

            if (useBeacon && navigator.sendBeacon) {
                const blob = new Blob([JSON.stringify(data)], {
                    type: 'application/json',
                })
                navigator.sendBeacon(url, blob)
            } else {
                try {
                    this.$rest.post(url, data, { overlay: false })
                } catch (error) {
                    console.error(error)
                }
            }
        },
        handleHeartbeat() {
            const currentTime = Date.now()

            if (this.heartbeatStartedAt === null) {
                this.heartbeatStartedAt = currentTime
            }

            clearTimeout(this.inactivityTimeout)

            this.inactivityTimeout = setTimeout(() => {
                this.logHeartbeat(this.heartbeatStartedAt, Date.now())
                this.heartbeatStartedAt = null
            }, 1000)
        },
        handleHeartbeatPageUnload() {
            if (this.heartbeatStartedAt) {
                this.logHeartbeat(this.heartbeatStartedAt, Date.now(), true)
            }
        },
    },
    created() {
        document.addEventListener('mousemove', this.handleHeartbeat)
        document.addEventListener('keypress', this.handleHeartbeat)
        document.addEventListener('scroll', this.handleHeartbeat)
        document.addEventListener('click', this.handleHeartbeat)
        document.addEventListener('touchstart', this.handleHeartbeat)

        window.addEventListener('beforeunload', this.handleHeartbeatPageUnload)
    },
}
