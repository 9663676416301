<template>
    <div class="columns pagination">
        <div
            v-if="!hidePaginateBy"
            class="column is-narrow"
        >
            <T3ChoicesField
                :model-value="pagination"
                :choices="[
                    [20, 20],
                    [50, 50],
                    [100, 100],
                    [200, 200],
                ]"
                @input="requestPagination"
            />
        </div>
        <div
            v-if="pageCount > 1"
            class="column"
        >
            <nav
                class="pagination is-small"
                role="navigation"
                aria-label="pagination"
            >
                <a
                    v-if="hasPrevious"
                    class="pagination-previous"
                    @click="gotoPrevious"
                >
                    Previous
                </a>

                <a
                    v-if="hasNext"
                    class="pagination-next"
                    @click="gotoNext"
                >
                    Next page
                </a>

                <ul
                    v-show="!hidePaginateList"
                    class="pagination-list"
                >
                    <li>
                        <a
                            class="pagination-link"
                            :class="{ 'is-current': page === 1 }"
                            aria-label="Goto page 1"
                            @click="gotoPage(1)"
                        >
                            1
                        </a>
                    </li>
                    <li v-if="pageCount > 5 && page > 2">
                        <span class="pagination-ellipsis">&hellip;</span>
                    </li>
                    <li v-if="page > 2">
                        <a
                            class="pagination-link"
                            :aria-label="`Goto page ${page - 1}`"
                            @click="gotoPage(page - 1)"
                        >
                            {{ page - 1 }}
                        </a>
                    </li>
                    <li v-if="page > 1 && pageCount - page > 0">
                        <a
                            class="pagination-link is-current"
                            :aria-label="`Page ${page}`"
                            aria-current="page"
                            @click="gotoPage(page)"
                        >
                            {{ page }}
                        </a>
                    </li>
                    <li v-if="pageCount - page > 1">
                        <a
                            class="pagination-link"
                            :aria-label="`Goto page ${page + 1}`"
                            @click="gotoPage(page + 1)"
                        >
                            {{ page + 1 }}
                        </a>
                    </li>
                    <li v-if="pageCount > 5 && pageCount - page > 1">
                        <span class="pagination-ellipsis">&hellip;</span>
                    </li>
                    <li v-if="pageCount > 1">
                        <a
                            class="pagination-link"
                            :class="{ 'is-current': page === pageCount }"
                            :aria-label="`Goto page ${pageCount}`"
                            @click="gotoPage(pageCount)"
                        >
                            {{ pageCount }}
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        page: {
            type: Number,
            default: 1,
        },
        pageCount: {
            type: Number,
            default: 1,
        },
        pagination: {
            type: Number,
            default: 50,
        },
        hidePaginateBy: {
            type: Boolean,
            default: false,
        },
        hidePaginateList: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['pageRequested', 'paginationRequested'],
    computed: {
        hasPrevious() {
            return this.page > 1
        },
        hasNext() {
            return this.page < this.pageCount
        },
    },
    methods: {
        gotoPrevious() {
            this.$emit('pageRequested', this.page - 1)
        },
        gotoNext() {
            this.$emit('pageRequested', this.page + 1)
        },
        gotoPage(page) {
            if (page !== this.page) {
                this.$emit('pageRequested', page)
            }
        },
        requestPagination(event) {
            this.$emit('paginationRequested', parseInt(event.target.value))
        },
    },
}
</script>
