import { defineStore } from 'pinia'

export const useCompanyStore = defineStore('company', {
    state: () => ({
        companyFiscalName: '',
        companyfiscalNumber: '',
        companyLicense: '',
        companyAddress: [],
        companyJurisdiction: '',
        companyURL: '',
    }),
    actions: {
        initCompany(data) {
            this.companyFiscalName = data.FISCAL_NAME
            this.companyFiscalNumber = data.FISCAL_NUMBER
            this.companyAddress = data.ADDRESS
            this.companyLicense = data.LICENSE
            this.companyJurisdiction = data.JURISDICTION
            this.companyURL = data.URL
        },
    },
})
