<template>
    <div class="field has-addons">
        <T3TextField
            ref="textField"
            v-model="criteria"
            :placeholder="placeholder"
            left-icon="fas fa-search"
            expanded
            @keyup.enter="performSearch"
            @keyup.esc="clearSearch"
        />
        <div class="control">
            <T3Button
                label="Search"
                role="info"
                :background-color="buttonColor"
                @clicked="performSearch"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: 'Type a search criteria here',
        },
        disableEscClear: {
            type: Boolean,
            default: false,
        },
        allowEmptySearch: {
            type: Boolean,
            default: false,
        },
        buttonColor: {
            type: String,
            default: undefined,
        },
    },
    emits: ['update:modelValue', 'searchRequested', 'searchCleared'],
    computed: {
        criteria: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            },
        },
    },
    mounted() {
        if (!this.disableEscClear) {
            document.body.addEventListener('keyup', (e) => {
                if (['Esc', 'Escape'].includes(e.key)) {
                    this.clearSearch()
                }
            })
        }
    },
    methods: {
        performSearch() {
            const criteria = this.criteria.trim()
            if (criteria !== '' || this.allowEmptySearch) {
                this.$emit('searchRequested', criteria)
            } else if (criteria === '') {
                this.clearSearch()
            }
        },
        clearSearch() {
            this.criteria = ''
            this.$emit('searchCleared')
        },
        focus() {
            this.$refs.textField.focus()
        },
    },
}
</script>
