export default [
    {
        name: 'ProductList',
        path: 'products',
        component: () => import('./views/ProductList.vue'),
        meta: {
            title: 'Products',
            perms: 'tickets',
            icon: 'fa-solid fa-receipt',
        },
    },
    {
        name: 'ProductCreate',
        path: 'products/create',
        component: () => import('./views/ProductForm.vue'),
    },
    {
        name: 'ProductUpdate',
        path: 'products/:product',
        component: () => import('./views/ProductForm.vue'),
    },

    {
        name: 'ProductCategoryList',
        path: 'products/categories',
        component: () => import('./views/ProductCategoryList.vue'),
        meta: {
            title: 'Product Categories',
            perms: 'tickets',
            icon: 'fa-solid fa-receipt',
        },
    },
    {
        name: 'TransportList',
        path: 'transports',
        component: () => import('./views/TransportList.vue'),
        meta: {
            title: 'Transports',
            perms: 'tickets',
            icon: 'fa-solid fa-bus-alt',
        },
    },
    {
        name: 'TransportCreate',
        path: 'transports/create',
        component: () => import('./views/TransportForm.vue'),
    },
    {
        name: 'TransportUpdate',
        path: 'transports/:transport',
        component: () => import('./views/TransportForm.vue'),
    },

    {
        name: 'ProductRequestList',
        path: 'products/requests',
        component: () => import('./views/ProductRequestList.vue'),
        meta: {
            title: 'Products Requests',
            perms: 'tickets',
            icon: 'fa-solid fa-receipt',
        },
    },
    {
        name: 'ProductRequestUpdate',
        path: 'products/requests/:requestId',
        component: () => import('./views/ProductRequestForm.vue'),
    },
    {
        name: 'TransportRequestList',
        path: 'transports/requests',
        component: () => import('./views/TransportRequestList.vue'),
        meta: {
            title: 'Transports Requests',
            perms: 'tickets',
            icon: 'fa-solid fa-bus-alt',
        },
    },
    {
        name: 'TransportRequestUpdate',
        path: 'transports/requests/:requestId',
        component: () => import('./views/TransportRequestForm.vue'),
    },
]
