<template>
    <div class="field">
        <label
            v-if="label !== undefined"
            class="label is-small"
        >
            {{ labelText }}
        </label>

        <div class="field">
            <div
                class="file is-small has-name"
                :class="{ 'is-danger': errors.length > 0 }"
            >
                <label class="file-label">
                    <input
                        ref="input"
                        class="file-input"
                        type="file"
                        name="resume"
                        :accept="accept"
                        :readonly="readonly"
                        @change="onFileChange"
                    />
                    <span class="file-cta">
                        <span class="file-icon">
                            <i class="fas fa-upload"></i>
                        </span>
                        <span class="file-label">{{ buttonLabel }}</span>
                    </span>
                    <span
                        v-if="filename"
                        class="file-name"
                    >
                        {{ filename }}
                    </span>
                </label>
            </div>
        </div>

        <p
            v-for="error in errors"
            :key="error"
            class="help is-danger"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'

export default {
    name: 'T3UploadField',
    extends: Field,
    props: {
        modelValue: {
            type: Object,
            default: undefined,
        },
        buttonLabel: {
            type: String,
            default: 'Upload',
        },
        document: {
            type: Object,
            default: undefined,
        },
        accept: {
            type: String,
            default: undefined,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    data: () => ({
        file: null,
    }),
    computed: {
        filename() {
            if (this.file) {
                return this.file.name
            }
            if (this.document) {
                const docSplit = this.document.url.split('/')
                return docSplit[docSplit.length - 1]
            }
            return undefined
        },
    },
    methods: {
        onFileChange(event) {
            this.file = event.target.files[0]
            this.$emit('update:modelValue', this.file)
        },
        clear() {
            this.file = null
            this.$refs.input.value = null
        },
    },
}
</script>
