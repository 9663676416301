export default {
    props: {
        filters: {
            type: Object,
            default: undefined,
        },
        staticFilters: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        showFilters: false,
        applyFilters: false,
    }),
    computed: {
        $filters() {
            if (this.applyFilters) {
                const current = Object.fromEntries(
                    Object.entries(this.filters).filter(([field, value]) => {
                        if (value === null || value === undefined) {
                            return false
                        }
                        if (typeof value === 'string' && !value.trim()) {
                            return false
                        }
                        if (
                            field.startsWith('date$') &&
                            field.endsWith('__range') &&
                            Array.isArray(value)
                        ) {
                            if (
                                value.filter((x) => x.trim() !== '').length ===
                                0
                            ) {
                                return false
                            }
                        }
                        return true
                    })
                )
                return Object.assign({}, this.staticFilters || {}, current)
            }

            return this.staticFilters
        },
    },
    watch: {
        async staticFilters() {
            await this.fetchItems()
        },
    },
    methods: {
        async onFiltersApplyButtonClicked() {
            if (!this.applyFilters) {
                this.applyFilters = true
                await this.fetchItems()
            } else {
                this.applyFilters = false
                await this.fetchItems()
            }
        },
        async onFiltersClearButtonClicked() {
            Object.keys(this.filters).forEach((field) => {
                this.filters[field] = null
            })
            if (this.applyFilters) {
                this.applyFilters = false
                await this.fetchItems()
            }
        },
    },
}
