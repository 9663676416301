export default [
    {
        name: 'MailingList',
        path: 'mailings',
        component: () => import('./views/MailingList.vue'),
        meta: {
            title: 'Mailings',
            perms: 'mailings',
            icon: 'fa-solid fa-envelopes-bulk',
        },
    },
    {
        name: 'MailingCreate',
        path: 'mailings/create',
        component: () => import('./views/MailingForm.vue'),
    },
    {
        name: 'MailingUpdate',
        path: 'mailings/:mailing',
        component: () => import('./views/MailingForm.vue'),
    },
    {
        name: 'BlacklistedEmailList',
        path: 'mailings/blacklist',
        component: () => import('./views/BlacklistedEmailList.vue'),
        meta: {
            title: 'Mailings Blacklisted Emails',
            perms: 'mailings',
            icon: 'fa-solid fa-ban',
        },
    },
    {
        name: 'BlacklistedEmailCreate',
        path: 'mailings/blacklist/create',
        component: () => import('./views/BlacklistedEmailForm.vue'),
    },
]
