<template>
    <T3FormSection
        :title="title"
        :bordered="bordered"
    >
        <div class="columns">
            <div class="column is-6">
                <T3TextField
                    v-model="form.email"
                    label="Email"
                    left-icon="fa-solid fa-envelope"
                    :errors="errors.email"
                />
            </div>
            <div class="column is-3">
                <T3TextField
                    v-model="form.land_phone"
                    label="Land Phone"
                    left-icon="fa-solid fa-phone"
                    :errors="errors.land_phone"
                />
            </div>
            <div class="column is-3">
                <T3TextField
                    v-model="form.mobile_phone"
                    label="Mobile Phone"
                    left-icon="fa-solid fa-mobile-alt"
                    :errors="errors.mobile_phone"
                />
            </div>
        </div>

        <slot></slot>
    </T3FormSection>
</template>

<script>
export default {
    name: 'ContactFormSection',
    props: {
        title: {
            type: String,
            default: 'Contact',
        },
        bordered: {
            type: Boolean,
            default: false,
        },
        form: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
}
</script>
