export default [
    {
        name: 'AirportList',
        path: 'locations/airports',
        component: () => import('./views/AirportList.vue'),
        meta: {
            title: 'Airports',
            perms: 'locations',
            icon: 'fa-solid fa-plane',
        },
    },
    {
        name: 'AirportCreate',
        path: 'locations/airports/create',
        component: () => import('./views/AirportForm.vue'),
    },
    {
        name: 'AirportUpdate',
        path: 'locations/airports/:airport',
        component: () => import('./views/AirportForm.vue'),
    },
    {
        name: 'CountryList',
        path: 'locations/countries',
        component: () => import('./views/CountryList.vue'),
        meta: {
            title: 'Countries',
            perms: 'locations',
            icon: 'fa-solid fa-globe',
        },
    },
    {
        name: 'CountryCreate',
        path: 'locations/countries/create',
        component: () => import('./views/CountryForm.vue'),
    },
    {
        name: 'CountryUpdate',
        path: 'locations/countries/:country',
        component: () => import('./views/CountryForm.vue'),
    },
    {
        name: 'DivisionList',
        path: 'locations/divisions',
        component: () => import('./views/DivisionList.vue'),
        meta: {
            title: 'Divisions',
            perms: 'locations',
            icon: 'fa-solid fa-book',
        },
    },
    {
        name: 'DivisionCreate',
        path: 'locations/divisions/create',
        component: () => import('./views/DivisionForm.vue'),
    },
    {
        name: 'DivisionUpdate',
        path: 'locations/divisions/:division',
        component: () => import('./views/DivisionForm.vue'),
    },
    {
        name: 'LocationList',
        path: 'locations/locations',
        component: () => import('./views/LocationList.vue'),
        meta: {
            title: 'Locations',
            perms: 'locations',
            icon: 'fa-solid fa-map-marker',
        },
    },
    {
        name: 'LocationCreate',
        path: 'locations/locations/create',
        component: () => import('./views/LocationForm.vue'),
    },
    {
        name: 'LocationUpdate',
        path: 'locations/locations/:location',
        component: () => import('./views/LocationForm.vue'),
    },
]
