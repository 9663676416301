export default [
    {
        name: 'SalesChannelList',
        path: 'channels',
        component: () => import('./views/SalesChannelList.vue'),
        meta: {
            title: 'Sales Channels',
            perms: 'channels',
            icon: 'fa-solid fa-plug',
        },
    },
    {
        name: 'SalesChannelCreate',
        path: 'channels/create',
        component: () => import('./views/SalesChannelForm.vue'),
    },
    {
        name: 'SalesChannelUpdate',
        path: 'channels/:channel',
        component: () => import('./views/SalesChannelForm.vue'),
    },
]
