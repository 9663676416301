import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
    state: () => ({
        initialized: false,
        build: 0,
        requireReload: false,
    }),
    actions: {
        initApp() {
            this.build = parseInt(sessionStorage.getItem('build') || 0)
            this.initialized = true
        },
        setBuild(build) {
            if (build !== null && build !== this.build) {
                if (this.build !== 0) {
                    this.requireReload = true
                }
                this.build = build
                sessionStorage.setItem('build', build)
            }
        },
    },
})
