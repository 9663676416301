export default {
    props: {
        label: {
            type: String,
            default: undefined,
        },
        icon: {
            type: String,
            default: undefined,
        },
        errors: {
            type: Array,
            default: () => [],
        },
        rightAligned: {
            type: Boolean,
            default: false,
        },
    },
}
