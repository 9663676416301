import EmailList from './EmailList.vue'
import EmailTable from './EmailTable.vue'
import Pagination from './Pagination.vue'
import CircularProgress from './CircularProgress.vue'

export default function (app) {
    app.component('T3EmailList', EmailList)
    app.component('T3EmailTable', EmailTable)
    app.component('T3Pagination', Pagination)
    app.component('T3CircularProgress', CircularProgress)
}
