export default [
    {
        name: 'GlobalPreferences',
        path: 'admin/preferences',
        component: () => import('./views/GlobalPreferences.vue'),
        meta: {
            title: 'Global Preferences',
            perms: 'admin',
            icon: 'fa-solid fa-cogs',
        },
    },
    {
        name: 'AppsVersions',
        path: 'admin/versions',
        component: () => import('./views/AppsVersions.vue'),
        meta: {
            title: 'Versions',
            perms: 'versions',
            icon: 'fa-solid fa-tags',
        },
    },
]
