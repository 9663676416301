import LeadStatus from './LeadStatus.vue'
import ModelMultipleSelect from './ModelMultipleSelect.vue'
import ModificationStatus from './ModificationStatus.vue'
import RequestStatus from './RequestStatus.vue'
import StatusMultipleSelect from './StatusMultipleSelect.vue'

export default function (app) {
    app.component('T3LeadStatus', LeadStatus)
    app.component('T3ModelMultipleSelect', ModelMultipleSelect)
    app.component('T3ModificationStatus', ModificationStatus)
    app.component('T3RequestStatus', RequestStatus)
    app.component('T3StatusMultipleSelect', StatusMultipleSelect)
}
