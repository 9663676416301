export default [
    {
        name: 'TagList',
        path: 'tags',
        component: () => import('./views/TagList.vue'),
        meta: {
            title: 'Tags',
            perms: 'admin',
            icon: 'fa-solid fa-tags',
        },
    },
    {
        name: 'TagCreate',
        path: 'tags/create',
        component: () => import('./views/TagForm.vue'),
    },
    {
        name: 'TagUpdate',
        path: 'tags/:tag',
        component: () => import('./views/TagForm.vue'),
    },
]
