<template>
    <span>{{ stars }}</span>
</template>

<script>
export default {
    name: 'StarsRating',
    props: {
        modelValue: {
            type: Number,
            default: 0,
        },
        compact: {
            type: Boolean,
            default: false,
        },
        noEmpty: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        stars() {
            if (this.compact) {
                return `${this.modelValue}★`
            }

            let stars = ''

            if (this.noEmpty) {
                for (let i = 0; i < this.modelValue; i++) {
                    stars += '★'
                }
            } else {
                for (let i = 0; i < 5; i++) {
                    if (i < this.modelValue) {
                        stars += '★'
                    } else {
                        stars += '☆'
                    }
                }
            }

            return stars
        },
    },
}
</script>
