import clone from 'lodash.clone'
import cloneDeep from 'lodash.clonedeep'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'

import { percent } from '@/helpers/filters'

import { last } from '@/helpers/arrays'

import { copyStringToClipboard } from '@/helpers/clipboard'

import {
    isDate,
    isTime,
    isDatetime,
    parseDate,
    parseDatetime,
    dateToString,
    nextDay,
    previousDay,
    today,
    tomorrow,
    datePlusDays,
    daysDelta,
    datesDifferenceInDays,
} from '@/helpers/dates'

import { isEmail } from '@/helpers/emails'

import { isModel, isSameModel, pkToId } from '@/helpers/models'

import { twoZeroPad, parseNumber } from '@/helpers/numbers'

import { randHex } from '@/helpers/random'

import {
    format,
    underize,
    snakeToTitle,
    levenstein,
    capitalize,
} from '@/helpers/strings'

import { langName, langFlag } from '@/helpers/i18n'

import { mimetypeExtension, fullFilename } from '@/helpers/mimetypes'

import { currencyIcon, formatCurrency } from '@/helpers/currency'

import { createAutocompleteClient } from '@/plugins/autocomplete'

import RestClient from '@/plugins/rest'

export default function (app) {
    app.config.globalProperties.$_ = {
        clone,
        cloneDeep,
        isEmpty,
        isNil,
    }

    app.config.globalProperties.$percent = percent

    /* Array Functions */

    Array.prototype.last = last

    /* String Functions */

    String.prototype.format = format
    String.prototype.underize = underize
    String.prototype.levenstein = levenstein
    String.prototype.capitalize = capitalize

    app.config.globalProperties.$snakeToTitle = snakeToTitle
    app.config.globalProperties.$twoZeroPad = twoZeroPad

    /* Value Testing */

    app.config.globalProperties.$isModel = isModel
    app.config.globalProperties.$isSameModel = isSameModel
    app.config.globalProperties.$pkToId = pkToId

    app.config.globalProperties.$isDate = isDate
    app.config.globalProperties.$isTime = isTime
    app.config.globalProperties.$isDatetime = isDatetime

    app.config.globalProperties.$isEmail = isEmail

    /* Parsing Functions */

    app.config.globalProperties.$parseNumber = parseNumber

    app.config.globalProperties.$parseDate = parseDate
    app.config.globalProperties.$parseDatetime = parseDatetime

    /* Date Functions */

    app.config.globalProperties.$dateToString = dateToString
    app.config.globalProperties.$nextDay = nextDay
    app.config.globalProperties.$previousDay = previousDay
    app.config.globalProperties.$today = today
    app.config.globalProperties.$tomorrow = tomorrow
    app.config.globalProperties.$datePlusDays = datePlusDays
    app.config.globalProperties.$daysDelta = daysDelta
    app.config.globalProperties.$datesDifferenceInDays = datesDifferenceInDays

    /* Random Functions */

    app.config.globalProperties.$randHex = randHex

    /* Clipboard Functions */

    app.config.globalProperties.$copyStringToClipboard = copyStringToClipboard

    /* I18N Functions */

    app.config.globalProperties.$langName = langName
    app.config.globalProperties.$langFlag = langFlag

    /* MIME Types */
    app.config.globalProperties.$mimetypeExtension = mimetypeExtension
    app.config.globalProperties.$fullFilename = fullFilename

    /* Currency Functions */
    app.config.globalProperties.$currencyIcon = currencyIcon
    app.config.globalProperties.$formatCurrency = formatCurrency

    /* Setting Autocomplete */
    const autocompleteClient = createAutocompleteClient()
    app.config.globalProperties.$autocomplete = autocompleteClient

    /* REST Client */
    app.config.globalProperties.$rest = RestClient
}
