<template>
    <T3TabPage
        label="Social"
        icon="fas fa-hashtag"
    >
        <div class="columns">
            <div class="column">
                <T3TextField
                    v-model="form.website_url"
                    label="Website URL"
                    left-icon="fa-solid fa-globe"
                    placeholder="https://www.example.com/..."
                />
            </div>
            <div
                v-if="form.website_url"
                class="column is-narrow is-vertical-bottom ml-0 pl-0"
            >
                <a
                    class="button is-info is-small"
                    :href="form.website_url"
                    target="_blank"
                >
                    <span class="icon">
                        <i class="fa-solid fa-arrow-up-right-from-square"></i>
                    </span>
                </a>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <T3TextField
                    v-model="form.facebook_url"
                    label="Facebook URL"
                    left-icon="fab fa-facebook"
                    placeholder="https://www.facebook.com/..."
                />
            </div>
            <div
                v-if="form.facebook_url"
                class="column is-narrow is-vertical-bottom ml-0 pl-0"
            >
                <a
                    class="button is-info is-small"
                    :href="form.facebook_url"
                    target="_blank"
                >
                    <span class="icon">
                        <i class="fa-solid fa-arrow-up-right-from-square"></i>
                    </span>
                </a>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <T3TextField
                    v-model="form.instagram_url"
                    label="Instagram URL"
                    left-icon="fab fa-instagram"
                    placeholder="https://www.instagram.com/..."
                />
            </div>
            <div
                v-if="form.instagram_url"
                class="column is-narrow is-vertical-bottom ml-0 pl-0"
            >
                <a
                    class="button is-info is-small"
                    :href="form.instagram_url"
                    target="_blank"
                >
                    <span class="icon">
                        <i class="fa-solid fa-arrow-up-right-from-square"></i>
                    </span>
                </a>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <T3TextField
                    v-model="form.youtube_url"
                    label="Youtube URL"
                    left-icon="fab fa-youtube"
                    placeholder="https://www.youtube.com/..."
                />
            </div>
            <div
                v-if="form.youtube_url"
                class="column is-narrow is-vertical-bottom ml-0 pl-0"
            >
                <a
                    class="button is-info is-small"
                    :href="form.youtube_url"
                    target="_blank"
                >
                    <span class="icon">
                        <i class="fa-solid fa-arrow-up-right-from-square"></i>
                    </span>
                </a>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <T3TextField
                    v-model="form.tiktok_url"
                    label="Tik Tok URL"
                    left-icon="fab fa-tiktok"
                    placeholder="https://www.tiktok.com/..."
                />
            </div>
            <div
                v-if="form.tiktok_url"
                class="column is-narrow is-vertical-bottom ml-0 pl-0"
            >
                <a
                    class="button is-info is-small"
                    :href="form.tiktok_url"
                    target="_blank"
                >
                    <span class="icon">
                        <i class="fa-solid fa-arrow-up-right-from-square"></i>
                    </span>
                </a>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <T3TextField
                    v-model="form.twitter_url"
                    label="Twitter URL"
                    left-icon="fab fa-twitter"
                    placeholder="https://www.twitter.com/..."
                />
            </div>
            <div
                v-if="form.twitter_url"
                class="column is-narrow is-vertical-bottom ml-0 pl-0"
            >
                <a
                    class="button is-info is-small"
                    :href="form.twitter_url"
                    target="_blank"
                >
                    <span class="icon">
                        <i class="fa-solid fa-arrow-up-right-from-square"></i>
                    </span>
                </a>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <T3TextField
                    v-model="form.linkedin_url"
                    label="LinkedIn URL"
                    left-icon="fab fa-linkedin"
                    placeholder="https://www.linkedin.com/..."
                />
            </div>
            <div
                v-if="form.linkedin_url"
                class="column is-narrow is-vertical-bottom ml-0 pl-0"
            >
                <a
                    class="button is-info is-small"
                    :href="form.linkedin_url"
                    target="_blank"
                >
                    <span class="icon">
                        <i class="fa-solid fa-arrow-up-right-from-square"></i>
                    </span>
                </a>
            </div>
        </div>
    </T3TabPage>
</template>

<script>
export default {
    name: 'T3SocialTab',
    props: {
        form: {
            type: Object,
            required: true,
        },
    },
}
</script>
