<template>
    <div
        class="flex items-center justify-center relative"
        @click="onButtonClicked"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
    >
        <div
            class="w-8 h-8 relative hover:bg-gray rounded flex justify-center items-center cursor-pointer text-[#78808A] hover:text-[#111827] duration-100 dark:text-[#9CA3AF] hover:dark:text-white hover:dark:bg-[#374151]"
            @click="$emit('click')"
        >
            <i
                :class="icon"
                class="text-[20px]"
            ></i>
        </div>
        <div
            v-if="hover"
            class="whitespace-nowrap !font-normal absolute bg-[rgba(0,0,0,0.7)] text-white p-[0.2rem_0.5rem] rounded-[0.3rem] text-[0.8rem] duration-100 translate-x-[-50%] z-50 -top-8"
        >
            {{ tooltip }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'RichTextAreaFieldButton',
    props: {
        icon: {
            type: String,
            required: true,
        },
        tooltip: {
            type: String,
            default: undefined,
        },
        wrapper: {
            type: Boolean,
            default: false,
        },
        buttonActive: {
            type: Boolean,
            default: false,
        },
        paletteWrapper: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        hover: false,
    }),
    methods: {
        onButtonClicked() {
            this.$emit('click')
        },
    },
}
</script>
