<template>
    <div class="tabs is-small is-toggle is-toggle-rounded">
        <ul>
            <li
                v-for="[lang, label] in choices"
                :key="lang"
                :class="{ 'is-active': lang === modelValue }"
                @click="onLangClicked(lang)"
            >
                <a>
                    <T3LangFlag :model-value="lang" />
                    <span class="ml-2">{{ label }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import { defaultLangs, langName } from '@/helpers/i18n'

export default {
    name: 'T3LangTabField',
    props: {
        modelValue: {
            type: String,
            default: 'en',
        },
    },
    emits: ['update:modelValue'],
    computed: {
        choices() {
            return defaultLangs().map((lang) => [lang, langName(lang)])
        },
    },
    methods: {
        onLangClicked(lang) {
            this.$emit('update:modelValue', lang)
        },
    },
}
</script>
