<template>
    <T3Modal
        ref="modal"
        :title="title"
    >
        <slot>Do you want to confirm this action?</slot>
        <template #footer>
            <div class="buttons">
                <T3Button
                    :label="cancelLabel"
                    @clicked="onCancelClicked"
                />
                <T3Button
                    :role="confirmRole"
                    :label="confirmLabel"
                    @clicked="onConfirmClicked"
                />
            </div>
        </template>
    </T3Modal>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Action Confirmation',
        },
        cancelLabel: {
            type: String,
            default: 'Cancel',
        },
        confirmLabel: {
            type: String,
            default: 'Confirm',
        },
        confirmRole: {
            type: String,
            default: 'primary',
        },
    },
    emits: ['confirmed', 'rejected'],
    data: () => ({
        payload: undefined,
    }),
    methods: {
        open(payload = undefined) {
            this.payload = payload
            this.$refs.modal.show()
        },
        onCancelClicked() {
            this.$emit('rejected', this.payload)
            this.payload = undefined
            this.$refs.modal.hide()
        },
        onConfirmClicked() {
            this.$emit('confirmed', this.payload)
            this.payload = undefined
            this.$refs.modal.hide()
        },
    },
}
</script>
