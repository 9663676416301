<template>
    <span :class="classes">
        {{ choicesLabel('RequestStatus', status) }}
    </span>
</template>

<script>
export default {
    props: {
        status: {
            type: Number,
            required: true,
        },
        link: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        classes() {
            return `tag status-${this.status}`
        },
    },
}
</script>

<style scoped>
.status-0 {
    background-color: #e03b24 !important;
    color: white !important;
}

.status-1 {
    background-color: #64a338 !important;
    color: white !important;
}

.status-2 {
    background-color: #873600 !important;
    color: white !important;
}

.status-3 {
    background-color: #873600 !important;
    color: white !important;
}

.status-4 {
    background-color: #000000 !important;
    color: white !important;
}

.status-5 {
    background-color: #ffcc00 !important;
    color: black !important;
}

.status-6 {
    background-color: #e03b24 !important;
    color: white !important;
}
</style>
