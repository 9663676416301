<template>
    <div class="field">
        <label
            v-if="label"
            class="label is-small pr-3"
            :class="{
                'has-text-right': rightAligned,
            }"
        >
            {{ labelText }}
        </label>

        <div
            :class="{
                control: true,
                'is-expanded': expanded,
            }"
        >
            <div
                :class="{
                    select: true,
                    'is-small': true,
                    'is-danger': hasErrors,
                    'is-fullwidth': expanded,
                }"
            >
                <select
                    v-model="choice"
                    v-bind="$attrs"
                    :disabled="readonly || undefined"
                >
                    <template v-if="isModel">
                        <option
                            v-for="opt in $selectedChoices"
                            :key="opt[pkField]"
                            :value="opt[pkField]"
                        >
                            {{ opt.label }}
                        </option>
                    </template>

                    <template v-else>
                        <option
                            v-for="[value, label] in $selectedChoices"
                            :key="value"
                            :value="value"
                        >
                            {{ label }}
                        </option>
                    </template>
                </select>
            </div>
        </div>
        <p
            v-for="error in errors"
            :key="error"
            class="help is-danger"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'
import SchemaMixin from '@/mixins/SchemaMixin'

export default {
    name: 'T3ChoicesField',
    extends: Field,
    mixins: [SchemaMixin],
    props: {
        modelValue: {
            type: [String, Number, Boolean, Object],
            default: null,
        },
        choices: {
            type: [Array, String],
            required: true,
        },
        isBoolean: {
            type: Boolean,
            default: false,
        },
        nullable: {
            type: Boolean,
            default: false,
        },
        isModel: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    computed: {
        choice: {
            get() {
                if (this.modelValue) {
                    if (this.isModel && typeof this.modelValue === 'object') {
                        return this.modelValue[this.pkField]
                    }
                }

                return this.modelValue
            },
            set(newval) {
                let value = newval
                if (this.isBoolean) {
                    if (newval === 'true' || newval === true) {
                        value = true
                    } else if (newval === 'false' || newval === false) {
                        value = false
                    } else {
                        value = null
                    }
                }
                if (this.nullable && value === null) {
                    this.$emit('update:modelValue', null)
                } else {
                    if (this.isModel) {
                        const obj = this.$selectedChoices.find(
                            (o) => o[this.pkField] === value
                        )
                        if (obj) {
                            this.$emit('update:modelValue', obj)
                        }
                    } else {
                        this.$emit('update:modelValue', value)
                    }
                }
            },
        },
        $selectedChoices() {
            if (Array.isArray(this.choices)) {
                return this.choices
            } else if (typeof this.choices === 'string') {
                return this.choicesList(this.choices)
            }
            return null
        },
    },
}
</script>
