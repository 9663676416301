export default {
    props: {
        leftIcon: {
            type: String,
            default: undefined,
        },
        rightIcon: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        iconClasses() {
            let classes = []
            if (this.leftIcon) {
                classes.push('has-icons-left')
            }
            if (this.rightIcon) {
                classes.push('has-icons-right')
            }
            return classes
        },
    },
}
