<template>
    <div
        class="field"
        :class="extraFieldClasses"
    >
        <label
            v-if="label"
            class="label is-small pr-3"
            :class="{
                'has-text-right': rightAligned,
            }"
        >
            {{ labelText }}
        </label>

        <div
            class="control"
            :class="iconClasses"
        >
            <input
                v-bind="$attrs"
                class="input is-small has-text-right"
                :class="inputClasses"
                :disabled="readonly || undefined"
                :value="displayValue"
                inputmode="decimal"
                @input="onInput"
                @blur="onBlur"
            />

            <span
                v-if="leftIcon !== undefined"
                class="icon is-left is-small"
            >
                <i :class="leftIcon" />
            </span>
            <span
                v-if="rightIcon !== undefined"
                class="icon is-right is-small"
            >
                <i :class="rightIcon" />
            </span>
        </div>
        <p
            v-for="error in errors"
            :key="error"
            class="help is-danger"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'
import InputIconsMixin from './InputIconsMixin'

export default {
    name: 'T3NumberField',
    extends: Field,
    mixins: [InputIconsMixin],
    props: {
        modelValue: {
            type: [Number, null],
            default: null,
        },
        decimals: {
            type: Number,
            default: 2,
        },
        nullable: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        minValue: {
            type: Number,
            default: undefined,
            required: false,
        },
        maxValue: {
            type: Number,
            default: undefined,
        },
        extraFieldClasses: {
            type: String,
            default: '',
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            displayValue: this.formatValue(this.modelValue),
        }
    },
    watch: {
        modelValue(newval) {
            this.displayValue = this.formatValue(newval)
        },
    },
    methods: {
        formatValue(value) {
            if (value === null || isNaN(value)) {
                return ''
            }
            const fixedValue = value.toFixed(this.decimals)
            return parseFloat(fixedValue).toString()
        },
        onInput(event) {
            const input = event.target.value
            const regex = new RegExp(`^\\d*(\\.\\d{0,${this.decimals}})?$`)
            if (regex.test(input)) {
                this.displayValue = input
                const numericValue = parseFloat(input)
                if (!isNaN(numericValue)) {
                    this.$emit('update:modelValue', numericValue)
                } else {
                    this.$emit('update:modelValue', null)
                }
            } else {
                event.target.value = this.displayValue
            }
        },
        onBlur() {
            const numericValue = parseFloat(this.displayValue)
            if (!isNaN(numericValue)) {
                let value = numericValue
                if (this.minValue !== undefined && value < this.minValue) {
                    value = this.minValue
                }
                if (this.maxValue !== undefined && value > this.maxValue) {
                    value = this.maxValue
                }
                value = parseFloat(value.toFixed(this.decimals))
                this.$emit('update:modelValue', value)
                this.displayValue = this.formatValue(value)
            } else {
                this.$emit('update:modelValue', null)
                this.displayValue = ''
            }
        },
    },
}
</script>
