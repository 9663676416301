<template>
    <div
        v-click-outside="closeSelectLangDropdown"
        class="field"
    >
        <label
            v-if="label !== undefined"
            class="label is-small"
        >
            {{ label }}
        </label>

        <div class="control">
            <div
                ref="field"
                class="field has-addons"
            >
                <div class="control is-expanded">
                    <textarea
                        v-model="modelValue[fieldName]"
                        class="textarea is-small"
                        :placeholder="placeholder"
                    >
                    </textarea>
                </div>
                <div class="control">
                    <a
                        class="button is-small"
                        @click="openSelectLangDropdown"
                    >
                        <span class="icon">
                            <i :class="langFlag(selectedLang)"></i>
                        </span>
                    </a>
                </div>
            </div>
        </div>
        <div
            ref="langDropdown"
            class="box"
            :style="{ display }"
        >
            <p
                v-for="lang in availableLangs"
                :key="lang"
                class="lang-item"
                @click="selectLang(lang)"
            >
                <span class="icon">
                    <i :class="langFlag(lang)"></i>
                </span>
                {{ langName(lang) }}
            </p>
        </div>
    </div>
</template>

<script>
import Field from './Field'
import I18NFieldMixin from './I18NFieldMixin'

export default {
    name: 'I18NTextAreaField',
    extends: Field,
    mixins: [I18NFieldMixin],
}
</script>

<style scoped>
.lang-item {
    cursor: pointer;
}
.lang-item:hover {
    color: #ea621f;
}
.box {
    z-index: 10000;
}
</style>
