export default [
    {
        name: 'AccountingEntryList',
        path: 'accounting/entries',
        component: () => import('./views/AccountingEntryList.vue'),
        meta: {
            title: 'Entries',
            perms: 'advisor',
        },
    },
    {
        name: 'AccountingEntryCreate',
        path: 'accounting/entries/create',
        component: () => import('./views/AccountingEntryForm.vue'),
    },
    {
        name: 'AccountingEntryUpdate',
        path: 'accounting/entries/:entry',
        component: () => import('./views/AccountingEntryForm.vue'),
    },
]
