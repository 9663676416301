import ConfirmationModal from './ConfirmationModal.vue'
import LocationSelectModal from './LocationSelectModal.vue'
import ModificationModal from './ModificationModal.vue'
import EmailMessageModal from './EmailMessageModal.vue'

export default function (app) {
    app.component('T3ConfirmationModal', ConfirmationModal)
    app.component('T3LocationSelectModal', LocationSelectModal)
    app.component('T3ModificationModal', ModificationModal)
    app.component('T3EmailMessageModal', EmailMessageModal)
}
