<template>
    <div class="field">
        <label
            v-if="label"
            class="label is-small"
        >
            {{ labelText }}
        </label>
        <div class="control has-icons-left flatpickr">
            <input
                v-bind="$attrs"
                ref="picker"
                class="input is-small"
                :class="inputClasses"
                :disabled="readonly"
                data-input
                @keyup.delete="reset"
            />
            <span class="icon is-left is-small">
                <i class="fas fa-calendar-alt"></i>
            </span>
        </div>
        <p
            v-for="error in errors"
            :key="error"
            class="help is-danger"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import flatpickr from 'flatpickr'

import Field from './Field'

export default {
    name: 'T3DateField',
    extends: Field,
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        format: {
            type: String,
            default: 'd/m/Y',
        },
        minValue: {
            type: String,
            default: undefined,
        },
        maxValue: {
            type: String,
            default: undefined,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    watch: {
        minValue() {
            this.createPicker()
        },
        maxValue() {
            this.createPicker()
        },
        readonly() {
            this.createPicker()
        },
        modelValue(newval) {
            if (newval) {
                this.lPicker.setDate(this.$parseDate(newval, this.format))
            } else {
                this.lPicker.setDate(null)
            }
        },
    },
    mounted() {
        this.createPicker()
    },
    methods: {
        createPicker() {
            if (this.lPicker) {
                delete this.lPicker
            }

            this.lPicker = flatpickr(this.$refs.picker, {
                allowInput: false,
                clickOpens: !this.readonly,
                dateFormat: this.format,
                minDate:
                    this.minValue !== undefined
                        ? this.$parseDate(this.minValue, this.format)
                        : undefined,
                maxDate:
                    this.maxValue !== undefined
                        ? this.$parseDate(this.maxValue, this.format)
                        : undefined,
                locale: {
                    firstDayOfWeek: 1,
                },
                onChange: this.onChange,
            })
            if (!this.$_.isNil(this.modelValue)) {
                this.lPicker.setDate(
                    this.$parseDate(this.modelValue, this.format)
                )
            }
        },
        reset() {
            this.lPicker.setDate(null)
            this.$emit('update:modelValue', null)
        },
        onChange(selectedDates) {
            this.$emit(
                'update:modelValue',
                this.$dateToString(selectedDates[0], this.format)
            )
        },
    },
}
</script>

<style scoped>
input {
    min-width: 7rem;
    max-width: 7rem;
}
</style>
