import isNil from 'lodash.isnil'
import { twoZeroPad } from './numbers'

export const isDate = function (value) {
    if (isNil(value) || value === '') {
        return false
    }
    return value.split('/').length === 3
}

export const isTime = function (value) {
    if (isNil(value) || value === '') {
        return false
    }
    const dtSplit = value.split(':')
    return dtSplit.length === 2 || dtSplit.length === 3
}

export const isFullTime = function (value) {
    if (isNil(value) || value === '') {
        return false
    }
    return value.split(':').length === 3
}

export const isDatetime = function (value) {
    if (isNil(value) || value === '') {
        return false
    }
    const comps = value.split(' ')
    if (comps.length !== 2) {
        return false
    }
    return isDate(comps[0]) && isTime(comps[1])
}

export const isFullDatetime = function (value) {
    if (isNil(value) || value === '') {
        return false
    }
    const comps = value.split(' ')
    if (comps.length !== 3) {
        return false
    }
    return isDate(comps[0]) && isFullTime(comps[1])
}

export const parseDate = function (dateString, format = 'd/m/Y') {
    if (!isNil(dateString) && dateString !== '') {
        if (format === 'd/m/Y') {
            let datesplit = dateString.split('/')
            return new Date(`${datesplit[1]} ${datesplit[0]} ${datesplit[2]}`)
        } else if (format === 'Y-m-d') {
            return new Date(dateString)
        }
    }
    return undefined
}

export const parseDatetime = function (value) {
    if (isDatetime(value)) {
        const [ds, ts] = value.split(' ')
        const [day, month, year] = ds.split('/')
        const dtSplit = ts.split(':')
        if (dtSplit.length === 2) {
            return new Date(
                parseInt(year),
                parseInt(month) - 1,
                parseInt(day),
                parseInt(dtSplit[0]),
                parseInt(dtSplit[1]),
                0
            )
        }

        if (dtSplit.length === 3) {
            return new Date(
                parseInt(year),
                parseInt(month) - 1,
                parseInt(day),
                parseInt(dtSplit[0]),
                parseInt(dtSplit[1]),
                parseInt(dtSplit[2])
            )
        }
    }
    if (value && value.includes('T')) {
        return new Date(value)
    }
    return undefined
}

export const dateToString = function (date, format = 'd/m/Y') {
    if (date !== undefined) {
        if (format === 'd/m/Y') {
            const day = twoZeroPad(date.getDate())
            const month = twoZeroPad(date.getMonth() + 1)
            const year = date.getFullYear()
            return `${day}/${month}/${year}`
        } else if (format === 'Y-m-d') {
            const day = twoZeroPad(date.getDate())
            const month = twoZeroPad(date.getMonth() + 1)
            const year = date.getFullYear()
            return `${year}-${month}-${day}`
        }
    }
    return ''
}

export const nextDay = function (datestr) {
    let date = parseDate(datestr)
    if (!isNil(date)) {
        date.setDate(date.getDate() + 1)
        return dateToString(date)
    }
    return ''
}

export const previousDay = function (datestr) {
    let date = parseDate(datestr)
    if (!isNil(date)) {
        date.setDate(date.getDate() - 1)
        return dateToString(date)
    }
    return ''
}

export const today = function () {
    return dateToString(new Date())
}

export const tomorrow = function () {
    return nextDay(today())
}

export const datePlusDays = function (datestr, days) {
    let date = parseDate(datestr)
    if (!isNil(date)) {
        date.setDate(date.getDate() + days)
        return dateToString(date)
    }
    return ''
}

export const daysDelta = function (datestr, delta) {
    let date = parseDate(datestr)
    if (!isNil(date)) {
        date.setDate(date.getDate() + delta)
        return dateToString(date)
    }
    return 0
}

export const datesDifferenceInDays = function (first_date, last_date) {
    return Math.round((parseDate(last_date) - parseDate(first_date)) / 86400000)
}
