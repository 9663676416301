<template>
    <div class="mb-4">
        <p
            v-for="error in errors"
            :key="error"
            class="help is-danger"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'T3ValidationErrors',
    props: {
        errors: {
            type: Array,
            required: true,
        },
    },
}
</script>
