<template>
    <div
        class="container-switch"
        @click.prevent="onClick"
    >
        <label v-if="label">{{ labelText }}</label>
        <label class="container-switch__switch">
            <input
                type="checkbox"
                :checked="modelValue"
            />
            <span class="slider round"></span>
        </label>
    </div>
</template>

<script>
import Field from '@/components/controls/Field'

export default {
    name: 'ToggleSwitch',
    extends: Field,
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    methods: {
        onClick() {
            this.$emit('update:modelValue', !this.modelValue)
        },
    },
}
</script>

<style scoped lang="scss">
.container-switch {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__switch {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 18px;
        justify-self: flex-end;
        cursor: pointer;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }
    }
}

.slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ddd;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #ea621f;
}

input:checked + .slider:before {
    transform: translateX(11px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
