<template>
    <div class="columns is-multiline my-0">
        <div
            v-for="[pk, label] in choices"
            :key="pk"
            class="column"
            :class="columnSize"
        >
            <div
                class="box py-1 my-2"
                :class="{
                    selected: modelValue === pk,
                    'has-text-weight-bold': modelValue === pk,
                }"
                @click="onButtonClicked(pk)"
            >
                <span class="icon">
                    <i
                        v-if="modelValue !== pk"
                        class="fa-regular fa-circle"
                    ></i>
                    <i
                        v-else
                        class="fa-regular fa-circle-check"
                    ></i>
                </span>
                {{ label }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: [String, Number],
            default: undefined,
        },
        columns: {
            type: Number,
            default: 1,
        },
        choices: {
            type: Array,
            required: true,
        },
    },
    emits: ['update:modelValue'],
    computed: {
        columnSize() {
            const size = Math.trunc(12 / this.columns)
            return `is-${size}`
        },
    },
    methods: {
        onButtonClicked(pk) {
            if (this.modelValue === pk) {
                this.$emit('update:modelValue', undefined)
            } else {
                this.$emit('update:modelValue', pk)
            }
        },
    },
}
</script>

<style scoped>
.box {
    cursor: pointer;
}
.selected {
    background-color: rgba(234, 98, 31, 0.2);
}
</style>
