import { useUIStore } from '@/stores/ui'

const MIME_TYPES = {
    XLSX: 'application/vnd.ms-excel',
    PDF: 'application/pdf',
}

class RestClient {
    constructor(baseUrl = undefined, token = undefined) {
        if (!baseUrl) {
            this.baseUrl = import.meta.env.VITE_APP_BACKEND + '/v2'
        } else {
            this.baseUrl = baseUrl
        }
        this.token = token
    }

    get headers() {
        const headers = {
            Accept: 'application/json',
            'T3-SITEID': 'admin',
        }
        if (this.token) {
            headers['Authorization'] = `Bearer ${this.token}`
        }
        return headers
    }

    setToken(token) {
        this.token = token
    }

    async _request({ method, resource, body, overlay, filename }) {
        let uiStore = undefined

        const showOverlay = overlay === true || overlay === undefined

        if (showOverlay) {
            uiStore = useUIStore()
            uiStore.startWorking()
        }
        try {
            // Populate headers
            const headers = {
                ...this.headers,
            }

            if (filename) {
                const ext = filename.split('.').pop().toUpperCase()
                if (MIME_TYPES[ext]) {
                    headers['Accept'] = MIME_TYPES[ext]
                }
            }

            // Populate options
            const options = {
                method,
                headers,
            }

            // Populate body
            if (body) {
                options.body = JSON.stringify(body)
            }

            // Make request
            const response = await fetch(`${this.baseUrl}${resource}`, options)
            const status = response.status

            // Process response

            // Check for errors
            if (status === 404) {
                throw {
                    type: 'server',
                    code: status,
                    reason: 'Not Found',
                }
            }

            if (status === 422) {
                const json = await response.json()
                Object.entries(json).forEach(([field, errors]) => {
                    json[field] = errors.map((x) => x.msg)
                })

                throw json
            }

            // Check for success
            if (status === 200) {
                if (filename) {
                    const blob = await response.blob()

                    const url = window.URL.createObjectURL(new Blob([blob]))
                    const link = document.createElement('a')

                    link.href = url
                    link.setAttribute(
                        'download',
                        filename ? filename : 'unknown'
                    )
                    document.body.appendChild(link)
                    link.click()

                    return null
                } else {
                    return await response.json()
                }
            }
        } finally {
            if (showOverlay) {
                uiStore.finishWorking()
            }
        }
    }

    async get(resource, params = undefined, options = {}) {
        if (params) {
            const urlParams = new URLSearchParams(params).toString()
            resource = `${resource}?${urlParams}`
        }

        return await this._request({
            method: 'GET',
            resource,
            body: undefined,
            overlay: options.overlay,
            filename: options.filename,
        })
    }

    async post(resource, body, options = {}) {
        return await this._request({
            method: 'POST',
            resource: resource,
            body: body,
            overlay: options.overlay,
            filename: undefined,
        })
    }

    async put(resource, body, options = {}) {
        return await this._request({
            method: 'PUT',
            resource: resource,
            body: body,
            overlay: options.overlay,
            filename: undefined,
        })
    }

    async patch(resource, body, options = {}) {
        return await this._request({
            method: 'PATCH',
            resource: resource,
            body: body,
            overlay: options.overlay,
            filename: undefined,
        })
    }

    async delete(resource, body, options = {}) {
        return await this._request({
            method: 'DELETE',
            resource: resource,
            body: body,
            overlay: options.overlay,
            filename: undefined,
        })
    }
}

export default new RestClient()
