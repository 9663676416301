export default [
    {
        name: 'AppList',
        path: 'apps',
        component: () => import('./views/AppList.vue'),
        meta: {
            title: 'Apps',
            perms: 'admin',
            icon: 'fa-solid fa-server',
        },
    },
    {
        name: 'AppCreate',
        path: 'apps/create',
        component: () => import('./views/AppForm.vue'),
    },
    {
        name: 'AppUpdate',
        path: 'apps/:app',
        component: () => import('./views/AppForm.vue'),
    },
    {
        name: 'AppTokensCreate',
        path: 'apps/:app/tokens',
        component: () => import('./views/AppTokensCreate.vue'),
    },
]
