import { defineStore } from 'pinia'

export const useThemeStore = defineStore('theme', {
    state: () => ({
        theme: 'light',
    }),
    getters: {
        isLight: (state) => state.theme === 'light',
    },
    actions: {
        /**
         * Initialize the theme based on localStorage, and apply the dark theme if set.
         */
        initialize() {
            this.theme = localStorage.getItem('theme') || 'light'
        },
        /**
         * Toggles the theme between light and dark, updates the local storage,
         * and adds or removes the 'dark' class from the document element.
         */
        toggle() {
            this.theme = this.theme === 'light' ? 'dark' : 'light'
            localStorage.setItem('theme', this.theme)
        },
    },
})
