<template>
    <T3TabPage
        label="Suppliers"
        icon="fa fa-parachute-box"
    >
        <T3ModelField
            v-model="supplier"
            placeholder="Type the supplier name ..."
            endpoint="/suppliers/autocomplete"
            right-button-icon="fas fa-plus"
            @right-button-clicked="onAddSupplierClicked"
            @enter-pressed="onAddSupplierClicked"
        />

        <div
            v-for="_supplier in modelValue.suppliers"
            :key="_supplier.pk"
            class="box"
        >
            <div class="level">
                <div class="level-left">
                    <div class="level-item title is-7">
                        {{ _supplier.label }}
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <T3Button
                            role="danger"
                            icon="fas fa-trash-alt"
                            @clicked="onRemoveSupplierClicked(_supplier)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </T3TabPage>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
        addEndpoint: {
            type: String,
            required: true,
        },
        removeEndpoint: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        supplier: null,
    }),
    computed: {
        pk() {
            return this.$route.params.pk
        },
    },
    methods: {
        async onAddSupplierClicked() {
            if (this.$isModel(this.supplier)) {
                const suppliers = await this.$request(this.addEndpoint, {
                    pk: this.pk,
                    supplier: this.supplier.pk,
                })
                this.modelValue.suppliers = suppliers
                this.supplier = null
            }
        },
        async onRemoveSupplierClicked(supplier) {
            const suppliers = await this.$request(this.removeEndpoint, {
                pk: supplier.pk,
            })
            this.modelValue.suppliers = suppliers
        },
    },
}
</script>

<style scoped>
.box {
    padding: 5px 10px;
    margin: 10px 0;
}
</style>
