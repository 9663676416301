export default function (app) {
    app.directive('click-outside', {
        beforeMount: function (el, binding) {
            const bubble = binding.modifiers.bubble
            const handler = (e) => {
                if (bubble || (!el.contains(e.target) && el !== e.target)) {
                    binding.value(e)
                }
            }
            el.__vueClickOutside__ = handler
            document.addEventListener('click', handler)
        },
        unmounted: function (el) {
            document.removeEventListener('click', el.__vueClickOutside__)
            el.__vueClickOutside__ = null
        },
    })
}
