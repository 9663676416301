<template>
    <div
        class="relative w-10 h-10 rounded flex justify-center items-center cursor-pointer text-[#78808A] hover:text-[#111827] dark:hover:text-white duration-100"
        @click="toggle"
    >
        <i
            v-if="isLight"
            class="fa-solid fa-moon text-[24px]"
        ></i>
        <i
            v-else
            class="fa-solid fa-sun text-[24px]"
        ></i>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useThemeStore } from '@/stores/theme'

export default {
    name: 'CRMDarkModeButton',
    computed: {
        ...mapState(useThemeStore, ['isLight']),
    },
    mounted() {
        this.initialize()
    },
    methods: {
        ...mapActions(useThemeStore, ['toggle', 'initialize']),
    },
}
</script>
