<template>
    <T3FormSection
        :title="title"
        :bordered="bordered"
    >
        <div class="columns">
            <div class="column">
                <T3TextField
                    v-model="form.address_line1"
                    label="Line 1"
                />
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <T3TextField
                    v-model="form.address_line2"
                    label="Line 2"
                />
            </div>
            <div class="column is-one-third">
                <T3TextField
                    v-model="form.address_zipcode"
                    label="Zipcode"
                />
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <T3CountryAutocompleteField
                    v-model="form.address_country"
                    :errors="errors.address_country"
                />
            </div>
            <div class="column">
                <T3DivisionAutocompleteField
                    v-model="form.address_division"
                    :country-id="form.address_country?.id"
                />
            </div>
            <div class="column">
                <T3LocationAutocompleteField
                    v-model="form.address_location"
                    :errors="errors.address_location"
                    :country-id="form.address_country?.id"
                    :division-id="form.address_division?.id"
                />
            </div>
        </div>

        <div
            v-if="geolocated"
            class="columns"
        >
            <div class="column is-one-third">
                <T3NumberField
                    v-model.number="form.latitude"
                    label="Latitude"
                    :decimals="6"
                />
            </div>
            <div class="column is-one-third">
                <T3NumberField
                    v-model.number="form.longitude"
                    label="Longitude"
                    :decimals="6"
                />
            </div>
        </div>
    </T3FormSection>
</template>

<script>
export default {
    name: 'AddressFormSection',
    props: {
        title: {
            type: String,
            default: 'Address',
        },
        bordered: {
            type: Boolean,
            default: false,
        },
        geolocated: {
            type: Boolean,
            default: false,
        },
        form: {
            type: Object,
            required: true,
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        pageErrors() {
            let result = {}
            if (this.errors.address_country) {
                result.address_country = this.errors.address_country
            }
            if (this.errors.address_location) {
                result.address_location = this.errors.address_location
            }
            return result
        },
        countryId() {
            return (
                this.form.address_country?.pk || this.form.address_country?.id
            )
        },
        divisionId() {
            return (
                this.form.address_division?.pk || this.form.address_division?.id
            )
        },
        divisionCountryId() {
            return this.form.address_division?.country_id
        },
        locationId() {
            return (
                this.form.address_location?.pk || this.form.address_location?.id
            )
        },
        locationCountryId() {
            return this.form.address_location?.country_id
        },
        locationDivisionId() {
            return this.form.address_location?.division_id
        },
    },
    watch: {
        countryId(newval) {
            this.$nextTick(() => {
                if (
                    newval &&
                    this.divisionId &&
                    newval != this.divisionCountryId
                ) {
                    this.form.address_division = null
                }
                if (
                    newval &&
                    this.locationId &&
                    newval != this.locationCountryId
                ) {
                    this.form.address_location = null
                }
            })
        },
        divisionId(newval) {
            this.$nextTick(() => {
                if (
                    newval &&
                    this.locationId &&
                    newval != this.locationDivisionId
                ) {
                    this.form.address_location = null
                }
            })
        },
    },
}
</script>
