<template>
    <div
        class="box"
        @click="onClicked"
    >
        <div class="columns">
            <div class="column is-narrow">
                <span class="icon has-text-primary is-large">
                    <i
                        class="fa-2x"
                        :class="icon"
                    ></i>
                </span>
            </div>
            <div class="column pl-0 ml-0 has-text-primary">
                <h5 class="title is-5">
                    {{ title }}
                </h5>
                {{ filename }}
            </div>
        </div>
    </div>
</template>

<script>
const ICONS = {
    png: 'fa-solid fa-file-image',
    jpg: 'fa-solid fa-file-image',
    jpeg: 'fa-solid fa-file-image',
    pdf: 'fa-solid fa-file-pdf',
    doc: 'fa-solid fa-file-word',
    docx: 'fa-solid fa-file-word',
    xls: 'fa-solid fa-file-excel',
    xlsx: 'fa-solid fa-file-excel',
    txt: 'fa-solid fa-file-text',
}

export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        bucketFile: {
            type: Object,
            required: true,
        },
    },
    computed: {
        filename() {
            return this.bucketFile?.filename
        },
        extension() {
            return this.filename?.split('.').pop().toLowerCase() || ''
        },
        icon() {
            return ICONS[this.extension] || 'fa-solid fa-file'
        },
    },
    methods: {
        async onClicked() {
            const bucket = this.bucketFile.bucket
            const uid = this.bucketFile.uid
            const filename = this.filename
            await this.$download(bucket, uid, filename)
        },
    },
}
</script>

<style scoped>
.box {
    cursor: pointer;
    background-color: rgba(234, 98, 31, 0.1);
}
</style>
