<template>
    <span :class="classes">
        <slot>
            {{ choicesLabel('ModificationStatus', status) }}
        </slot>
    </span>
</template>

<script>
export default {
    props: {
        status: {
            type: Number,
            required: true,
        },
    },
    computed: {
        classes() {
            return `tag status-${this.status}`
        },
    },
}
</script>

<style scoped>
.status-0 {
    background-color: #e03b24 !important;
    color: white !important;
}

.status-1 {
    background-color: #64a338 !important;
    color: white !important;
}

.status-2 {
    background-color: #873600 !important;
    color: white !important;
}
</style>
