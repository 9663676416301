<template>
    <T3ModelField
        v-model="hotel"
        right-icon="h-square"
        :label="label"
        :errors="errors"
        :endpoint="endpoint"
        :readonly="readonly"
        add-route="HotelCreate"
        edit-route="HotelUpdate"
        route-param-name="hotel"
    >
        <template #item="{ item }">
            <div class="columns px-1 py-2">
                <div class="column is-narrow is-vertical-center">
                    <span>{{ item.label }}</span>
                </div>
                <div class="column is-narrow is-vertical-center">
                    {{ item.stars }}
                </div>
                <div class="column"></div>
                <div
                    v-if="item.location"
                    class="column is-narrow is-vertical-center"
                >
                    <span class="tag is-black">{{ item.location }}</span>
                </div>
            </div>
        </template>

        <template #extra="{ item }">
            <span class="mr-2">{{ item.stars }}</span>
            <span
                v-if="item.location"
                class="tag is-black mr-3"
            >
                {{ item.location }}
            </span>
        </template>
    </T3ModelField>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            default: 'Hotel',
        },
        errors: {
            type: Array,
            default: undefined,
        },
        endpoint: {
            type: String,
            default: '/hotels/autocomplete',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    computed: {
        hotel: {
            get() {
                return this.modelValue
            },
            set(newval) {
                this.$emit('update:modelValue', newval)
            },
        },
    },
}
</script>
