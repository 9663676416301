export default [
    {
        name: 'NewCRMInbox',
        path: 'crm/newinbox',
        component: () => import('./views/NewCRMInbox.vue'),
        meta: {
            title: 'Inbox',
            perms: 'crm',
            icon: 'fa-solid fa-inbox',
            hiddenFooter: true,
        },
    },
]
