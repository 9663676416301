<template>
    <T3ModelField
        v-model="staff"
        right-icon="h-square"
        :pk-field="pkField"
        :label="label"
        :errors="errors"
        :endpoint="endpoint"
        :readonly="readonly"
        edit-route="EmployeeUpdate"
        route-param-name="employee"
    >
        <template #item="{ item }">
            <div class="columns px-1 py-2">
                <div class="column is-narrow is-vertical-center">
                    <span>{{ item.label }}</span>
                </div>
                <div class="column"></div>
                <div class="column is-narrow is-vertical-center">
                    <span class="tag is-info">
                        {{ choicesLabel('Department', item.department) }}
                    </span>
                </div>
            </div>
        </template>

        <template #extra="{ item }">
            <span class="tag is-info mr-4 mt-1">
                {{ choicesLabel('Department', item.department) }}
            </span>
        </template>
    </T3ModelField>
</template>

<script>
export default {
    props: {
        pkField: {
            type: String,
            default: 'pk',
        },
        modelValue: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            default: 'Staff',
        },
        errors: {
            type: Array,
            default: undefined,
        },
        endpoint: {
            type: String,
            default: '/hr/employees/autocomplete',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    computed: {
        staff: {
            get() {
                return this.modelValue
            },
            set(newval) {
                this.$emit('update:modelValue', newval)
            },
        },
    },
}
</script>
