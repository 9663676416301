<template>
    <MultipleAutocompleteField
        ref="multipleAutocomplete"
        :label="label"
        :selected="selected"
        :readonly="readonly"
        @added="onItemAdded"
    >
        <T3HotelAutocompleteField
            v-model="selected"
            label=""
            :country-id="countryId"
            :division-id="divisionId"
            :rating="rating"
            :geolocation="geolocation"
            :radius="radius"
        />

        <template #item="{ item }">
            <div class="columns is-marginless">
                <div class="column flag-column">
                    <span
                        v-if="item.country_id"
                        :class="`fi fi-${item.country_id.toLowerCase()}`"
                    >
                    </span>
                </div>
                <div class="column is-size-7 pt-1 ml-2">
                    <span>
                        <router-link
                            target="_blank"
                            :to="{
                                name: 'HotelUpdate',
                                params: {
                                    hotel: item.id || item.pk,
                                },
                            }"
                        >
                            {{ item.name || item.label }}
                        </router-link>
                    </span>
                    <span class="ml-1">{{ stars(item) }}</span>
                    <span
                        v-if="item.status"
                        class="tag ml-4 is-uppercase"
                        :class="{
                            'is-warning': item.status === 'unrequested',
                            'is-info': item.status === 'pending',
                            'is-success': item.status === 'available',
                            'is-danger': item.status === 'unavailable',
                        }"
                    >
                        {{ item.status }}
                    </span>
                    <span
                        v-if="item.distance"
                        class="ml-2"
                    >
                        {{ $fmt.km(item.distance / 1000.0) }} to location
                    </span>
                </div>
            </div>
        </template>
    </MultipleAutocompleteField>
</template>

<script>
import MultipleAutocompleteField from './MultipleAutocompleteField.vue'

export default {
    name: 'T3HotelMultipleAutocompleteField',
    components: {
        MultipleAutocompleteField,
    },
    props: {
        label: {
            type: String,
            default: 'Hotels',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        countryId: {
            type: String,
            default: '',
        },
        divisionId: {
            type: Number,
            default: 0,
        },
        rating: {
            type: Number,
            default: undefined,
        },
        geolocation: {
            type: Array,
            default: undefined,
        },
        radius: {
            type: Number,
            default: 3.0,
        },
    },
    data: () => ({
        selected: null,
    }),
    methods: {
        onItemAdded() {
            this.selected = null
        },
        onItemRemove(item) {
            this.$refs.multipleAutocomplete.remove(item)
        },
        stars(item) {
            switch (item.rating) {
                case 1:
                    return '★'
                case 2:
                    return '★★'
                case 3:
                    return '★★★'
                case 4:
                    return '★★★★'
                case 5:
                    return '★★★★★'
            }

            return ''
        },
    },
}
</script>

<style scoped>
.flag-column {
    width: 1.5rem;
    min-width: 1.5rem;
    max-width: 1.5rem;
}
</style>
