export default [
    {
        name: 'BackgroundTaskList',
        path: 'btasks',
        component: () => import('./views/BackgroundTaskListView.vue'),
        meta: {
            title: 'Background Tasks',
            perms: 'admin',
            icon: 'fa-solid fa-list-check',
        },
    },
    {
        name: 'BackgroundTaskUpdate',
        path: 'btasks/:task',
        component: () => import('./views/BackgroundTaskDetailsView.vue'),
    },
]
