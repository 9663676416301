<template>
    <div class="field has-addons">
        <T3TextField
            v-model="criteria"
            placeholder="Type a search criteria here"
            left-icon="fa-solid fa-search"
            expanded
            @keyup.enter="performSearch"
            @keyup.escape="clearSearch"
        />
        <div class="control">
            <T3Button
                label="Search"
                role="info"
                @clicked="performSearch"
            />
        </div>
    </div>
</template>

<script>
export default {
    emits: ['performSearch', 'clearSearch'],
    data: () => ({
        criteria: '',
    }),
    mounted() {
        document.body.addEventListener('keyup', (e) => {
            if (e.key === 27) {
                this.clearSearch()
            }
        })
    },
    methods: {
        performSearch() {
            const criteria = this.criteria.trim()
            if (criteria !== '') {
                this.$emit('performSearch', criteria)
            }
        },
        clearSearch() {
            this.criteria = ''
            this.$emit('clearSearch')
        },
    },
}
</script>
