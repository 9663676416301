<template>
    <T3TabPage
        label="Documents"
        icon="fa fa-book"
        :hidden="hidden"
    >
        <div class="columns">
            <div class="column">
                <T3FileField
                    :document-type="documentType"
                    :document-type-id="documentTypeId"
                    :document-upload-endpoint="documentUploadEndpoint"
                    :ignore-categories="ignoreCategories"
                    @document-uploaded="onDocumentUploaded"
                />
            </div>
        </div>
        <br />
        <div
            v-for="document in documents"
            :key="document.id"
            class="columns"
        >
            <div class="column">
                <a @click="onDocumentClicked(document)">
                    <span class="icon has-text-primary">
                        <i
                            class="fa"
                            :class="getIcon(document)"
                        ></i>
                    </span>
                    [{{ document.category }}] {{ document.name }}
                </a>
            </div>
        </div>
    </T3TabPage>
</template>

<script>
const ICONS = {
    png: 'fa fa-file-image',
    jpg: 'fa fa-file-image',
    jpeg: 'fa fa-file-image',
    pdf: 'fa fa-file-pdf',
    doc: 'fa fa-file-word',
    docx: 'fa fa-file-word',
    xls: 'fa fa-file-excel',
    xlsx: 'fa fa-file-excel',
    txt: 'fa fa-file-text',
}

export default {
    props: {
        documents: {
            type: Array,
            default: () => [],
        },
        documentType: {
            type: String,
            required: true,
        },
        documentTypeId: {
            type: [Number, String],
            required: true,
        },
        documentUploadEndpoint: {
            type: String,
            default: '/t3/documents/upload',
        },
        ignoreCategories: {
            type: Array,
            default: () => [],
        },
        hidden: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['documentUploaded'],
    methods: {
        async onDocumentClicked(document) {
            await this.$request(
                `/t3/documents/${document.id}/download`,
                undefined,
                document.filename
            )
        },
        onDocumentUploaded() {
            this.$emit('documentUploaded')
        },
        getIcon(document) {
            return ICONS[document.extension] || 'file-o'
        },
    },
}
</script>
