import { mapActions, mapState } from 'pinia'
import { useMessagesStore } from '@/stores/messages'
import { useAuthStore } from '@/stores/auth'
import { useChoicesStore } from '@/stores/choices'

export default {
    computed: {
        ...mapState(useChoicesStore, [
            'choicesList',
            'choicesRow',
            'choicesLabel',
        ]),
    },
    methods: {
        ...mapActions(useMessagesStore, [
            'msgError',
            'msgInfo',
            'msgSuccess',
            'msgWarning',
        ]),
        ...mapActions(useAuthStore, ['hasPerm', 'inGroup']),
    },
}
