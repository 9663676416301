<template>
    <div class="circular-progress">
        <svg
            :width="size"
            :height="size"
            viewBox="0 0 36 36"
        >
            <path
                class="circle-bg"
                d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
                :stroke="bgColor"
                :stroke-width="strokeWidth"
                fill="none"
            />
            <path
                class="circle"
                d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
                :stroke="progressColor"
                :stroke-width="strokeWidth"
                fill="none"
                :stroke-dasharray="circumference"
                :stroke-dashoffset="offset"
            />
            <text
                v-if="!hideText"
                x="18"
                y="18"
                class="percentage"
            >
                {{ $percent(modelValue) }}
            </text>
            <text
                v-if="!hideText"
                x="18"
                y="23"
                class="counts"
            >
                {{ completed }} / {{ total }}
            </text>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'CircularProgress',
    props: {
        modelValue: {
            type: Number,
            required: true,
            default: 0,
            validator: (value) => value >= 0 && value <= 100,
        },
        size: {
            type: Number,
            default: 100,
        },
        strokeWidth: {
            type: Number,
            default: 4,
        },
        progressColor: {
            type: String,
            default: '#EA621F',
        },
        bgColor: {
            type: String,
            default: '#eee',
        },
        taskId: {
            type: String,
            default: undefined,
        },
        refreshInterval: {
            type: Number,
            default: 1000,
        },
        hideText: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    data: () => ({
        completed: 0,
        total: 0,
        intervalId: null,
    }),
    computed: {
        radius() {
            return 15.9155
        },
        circumference() {
            return 2 * Math.PI * this.radius
        },
        offset() {
            return (
                this.circumference -
                (this.modelValue / 100) * this.circumference
            )
        },
    },
    async created() {
        if (this.taskId) {
            await this.fetchTask()
            this.intervalId = setInterval(this.fetchTask, this.refreshInterval)
        }
    },
    methods: {
        async fetchTask() {
            const taskId = this.taskId
            if (taskId) {
                try {
                    const progress = await this.$rest.get(
                        `/background_tasks/${this.taskId}`,
                        undefined,
                        {
                            overlay: false,
                        }
                    )
                    switch (progress?.status) {
                        case 'pending': {
                            this.$emit('update:modelValue', 0)
                            break
                        }
                        case 'started': {
                            const percentage = progress.progress
                            this.total = progress.total
                            this.completed = progress.completed
                            this.$emit('update:modelValue', percentage)
                            break
                        }
                        case 'finished': {
                            this.completed = this.total
                            this.$emit('update:modelValue', 100)
                            clearInterval(this.intervalId)
                            break
                        }
                    }
                } catch {
                    clearInterval(this.intervalId)
                }
            }
        },
    },
}
</script>

<style scoped>
.circular-progress {
    display: flex;
    align-items: center;
    justify-content: center;
}
.circle-bg {
    stroke-linecap: round;
}
.circle {
    stroke-linecap: round;
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}
.percentage {
    font-size: 0.33em;
    text-anchor: middle;
    fill: #333;
}

.counts {
    font-size: 0.25em;
    text-anchor: middle;
    fill: #333;
}
</style>
