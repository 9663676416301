export default {
    props: {
        schema: {
            type: String,
            default: 't3',
            validate: (schema) => ['t3', 'ninja'].includes(schema),
        },
    },
    computed: {
        pkField() {
            return this.schema === 't3' ? 'pk' : 'id'
        },
    },
}
