export default [
    {
        name: 'roomsFeatures',
        path: 'hotels/rooms/features',
        component: () => import('./rooms/features/list.vue'),
    },
    {
        name: 'HotelGroupList',
        path: 'hotels/groups',
        component: () => import('./groups/HotelGroupList.vue'),
    },
    {
        name: 'hotelCityTaxAnnotations',
        path: 'hotels/utilities/annotations/citytax',
        component: () => import('./utilities/CityTaxAnnotations.vue'),
    },
    {
        name: 'hotelGeographicSearch',
        path: 'hotels/utilities/geographic',
        component: () => import('./utilities/HotelGeographicSearch.vue'),
    },
    {
        name: 'HotelsBookedByCity',
        path: 'hotels/utilities/booked_by_city',
        component: () => import('./utilities/HotelsBookedByCity.vue'),
        meta: {
            title: 'Hotels Booked By City',
            perms: 'hotels',
            icon: 'fa-solid fa-hotel',
        },
    },
    {
        name: 'ExternalHotelList',
        path: 'hotels/external',
        component: () => import('./views/ExternalHotelList.vue'),
        meta: {
            title: 'External Hotels',
            perms: 'hotels',
            icon: 'fa-solid fa-hotel',
        },
    },
    {
        name: 'ExternalHotelPage',
        path: 'hotels/external/:hotel',
        component: () => import('./views/ExternalHotelPage.vue'),
    },

    // New Views

    {
        name: 'HotelsPage',
        path: 'hotels',
        component: () => import('./views/HotelsPage.vue'),
        meta: {
            title: 'Hotels',
            perms: 'hotels',
            icon: 'fa-solid fa-hotel',
        },
    },

    // StartSection - Chains

    {
        name: 'ChainList',
        path: 'hotels/chains',
        component: () => import('./chains/ChainList.vue'),
    },
    {
        name: 'ChainCreate',
        path: 'hotels/chains/create',
        component: () => import('./chains/ChainForm.vue'),
    },
    {
        name: 'ChainUpdate',
        path: 'hotels/chains/:chain',
        component: () => import('./chains/ChainForm.vue'),
    },
    // EndSection

    // StartSection - Facilities
    {
        name: 'FacilityList',
        path: 'hotels/facilities',
        component: () => import('./facilities/FacilityList.vue'),
    },
    {
        name: 'FacilityCreate',
        path: 'hotels/facilities/create',
        component: () => import('./facilities/FacilityForm.vue'),
    },
    {
        name: 'FacilityUpdate',
        path: 'hotels/facilities/:facility',
        component: () => import('./facilities/FacilityForm.vue'),
    },
    // EndSection

    // StartSection - Tags

    {
        name: 'HotelTagList',
        path: 'hotels/tags',
        component: () => import('./tags/HotelTagList.vue'),
    },
    {
        name: 'HotelTagCreate',
        path: 'hotels/tags/create',
        component: () => import('./tags/HotelTagForm.vue'),
    },
    {
        name: 'HotelTagUpdate',
        path: 'hotels/tags/:tag',
        component: () => import('./tags/HotelTagForm.vue'),
    },
    // EndSection

    // StartSection - Hotels
    {
        name: 'HotelList',
        path: 'hotels/list',
        component: () => import('./views/HotelListPage.vue'),
    },
    {
        name: 'HotelCreate',
        path: 'hotels/create',
        component: () => import('./views/HotelFormPage.vue'),
    },
    {
        name: 'HotelUpdate',
        path: 'hotels/:hotel',
        component: () => import('./views/HotelFormPage.vue'),
    },
    // EndSection
]
