import axios from 'axios'

const axiosObj = axios.create({
    baseURL: import.meta.env.VITE_APP_BACKEND,
    headers: {
        'T3-SITEID': 'admin',
    },
})

export default axiosObj
