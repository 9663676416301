import * as Sentry from '@sentry/vue'

export const createSentry = function (app, router) {
    if (
        ['devel', 'staging', 'brands', 'topgroup', 'toprock'].includes(
            import.meta.env.MODE
        )
    ) {
        Sentry.init({
            app,
            dsn: 'https://3978fcc16ce16f271c5f4035fce35fb3@bugs.topgroups.travel/3',
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            tracePropagationTargets: [
                'admin.topgroups.travel',
                'admin-staging.t3s.es',
                'admin-brands.t3s.es',
                'admin-devel.t3s.es',
                'admin.t3s.es', // DEPRECATED: staging before
                /^\//,
            ],
            integrations: [
                Sentry.browserTracingIntegration({ router }),
                Sentry.replayIntegration(),
            ],
            trackComponents: true,
            timeout: 500,
            hooks: ['activate', 'create', 'unmount', 'mount', 'update'],
        })

        Sentry.setTag('env', import.meta.env.MODE)
    }
}

export default {
    createSentry,
}
