import { defineStore } from 'pinia'
import rest from '../plugins/rest'

export const useChoicesStore = defineStore('choices', {
    state: () => ({
        choices: {},
    }),
    getters: {
        choicesList(state) {
            return (name) => state.choices[name]
        },
        choicesRow(state) {
            return (name, value) => {
                const choices = state.choices[name]
                if (choices === undefined) {
                    return ''
                }
                return choices.find((choice) => choice[0] === value)
            }
        },
        choicesLabel(state) {
            return (name, value) => {
                const choices = state.choices[name]
                if (choices === undefined) {
                    return ''
                }
                return choices.find((choice) => choice[0] === value)?.[1]
            }
        },
        bankAccountsForCurrency(state) {
            return (currency) => {
                return state.choices.BankAccounts.filter((item) => {
                    const currencies = item[2]
                    if (currencies.length === 0) {
                        return true
                    }
                    return currencies.includes(currency)
                })
            }
        },
        currenciesForBankAccount(state) {
            return (account) => {
                const currencies = state.choices.BankAccounts.find(
                    (item) => item[0] === account
                )?.[2]

                if (currencies && currencies.length > 0) {
                    return currencies.map((currency) => [currency, currency])
                }
                return state.choices.Currency
            }
        },
    },
    actions: {
        async fetchChoices() {
            try {
                const response = await rest.get('/choices')
                this.choices = response
            } catch (error) {
                console.error(error)
            }
        },
        async invalidateChoices() {
            try {
                const response = await rest.delete('/choices')
                this.choices = response
            } catch (error) {
                console.error(error)
            }
        },
    },
})
