import { mapActions, mapState } from 'pinia'
import { useUIStore } from '@/stores/ui'
import { useAuthStore } from '@/stores/auth'

import _axios from '../plugins/axios'

export default {
    computed: {
        ...mapState(useAuthStore, {
            $token: 'token',
        }),
    },
    methods: {
        ...mapActions(useUIStore, ['startWorking', 'finishWorking']),
        ...mapActions(useAuthStore, ['clearAuth']),
        __processMessages(messages) {
            if (messages) {
                messages.forEach((msg) => {
                    switch (msg.role) {
                        case 0: {
                            this.msgInfo(msg.body)
                            break
                        }
                        case 1: {
                            this.msgWarning(msg.body)
                            break
                        }
                        case 2: {
                            this.msgError(msg.body)
                            break
                        }
                        case 3: {
                            this.msgSuccess(msg.body)
                            break
                        }
                    }
                })
            }
        },
        setBearerToken(token) {
            if (token) {
                _axios.defaults.headers.common['Authorization'] =
                    `Bearer ${token}`
            } else {
                delete _axios.defaults.headers.common['Authorization']
            }
        },
        async $ping() {
            try {
                const response = await _axios({
                    url: '/t3/auth/ping',
                    method: 'POST',
                })

                const answer = {
                    connected: response.data.body.connected || false,
                    build: response.data.versions.backoffice,
                }

                return answer
            } catch (error) {
                if (error?.response?.data === 'Token Error: Expired') {
                    this.clearAuth()
                    return {
                        connected: false,
                        build: null,
                    }
                } else {
                    throw {
                        type: 'server',
                        code: error?.status,
                        reason: `${error?.statusText}`,
                    }
                }
            }
        },
        async $request(
            endpoint,
            data = undefined,
            filename = undefined,
            hideOverlay = false,
            overlayMessage = undefined
        ) {
            let req = {
                url: endpoint,
                method: 'POST',
                responseType: undefined,
                data,
            }

            if (filename) {
                req.responseType = 'blob'
            }

            if (!hideOverlay) {
                this.startWorking(overlayMessage)
            }

            try {
                const response = await _axios(req)

                if (filename) {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    )
                    const link = document.createElement('a')

                    link.href = url
                    link.setAttribute(
                        'download',
                        filename ? filename : 'unknown'
                    )
                    document.body.appendChild(link)
                    link.click()
                } else {
                    if (response?.data?.header?.answer === 'ok') {
                        this.__processMessages(response.data.messages)
                        return response.data.body
                    } else {
                        let error = {
                            type: 'system',
                            code: response?.data?.header?.code,
                            reason: response?.data?.header?.reason,
                            body: response?.data?.body,
                        }
                        throw error
                    }
                }
            } catch (error) {
                if (error?.type === 'system') {
                    switch (error.code) {
                        case 0: {
                            this.msgError(error.reason)
                            break
                        }
                        case 404: {
                            // router.replace({ name: 'not_found' })
                            break
                        }
                        case 1000: {
                            if (error.body.readonly) {
                                this.msgError(error.body.readonly[0])
                            } else {
                                this.msgError('Validation errors ocurred')
                                throw error
                            }
                            break
                        }
                        default: {
                            throw error
                        }
                    }
                } else {
                    if (error?.response?.data === 'Token Error: Expired') {
                        this.clearAuth()
                    } else {
                        throw {
                            type: 'server',
                            code: error?.status,
                            reason: `${error?.statusText}`,
                        }
                    }
                }
            } finally {
                if (!hideOverlay) {
                    this.finishWorking()
                }
            }
        },
        async $upload(file, overlay = true) {
            if (overlay) {
                this.startWorking()
            }

            const form = new FormData()
            form.append('file', file)

            try {
                const response = await _axios({
                    method: 'POST',
                    url: '/t3/documents/upload/document',
                    data: form,
                })
                return response.data
            } catch {
                throw {
                    answer: 'error',
                    code: -1,
                    reason: 'Unknown error has ocurred',
                }
            } finally {
                if (overlay) {
                    this.finishWorking()
                }
            }
        },
        async $uploadFile(file, overlay = true) {
            if (overlay) {
                this.startWorking()
            }

            const form = new FormData()
            form.append('file', file)

            try {
                const response = await _axios({
                    method: 'POST',
                    url: '/t3/storages/upload',
                    data: form,
                })
                return response.data
            } catch {
                throw {
                    answer: 'error',
                    code: -1,
                    reason: 'Unknown error has ocurred',
                }
            } finally {
                if (overlay) {
                    this.finishWorking()
                }
            }
        },
        async $download(bucket, uid, filename = undefined) {
            const endpoint = `/t3/storages/download/${bucket}/${uid}`
            await this.$downloadBucketFile(endpoint, filename)
        },
        async $downloadBucketFile(
            endpoint,
            filename = undefined,
            hideOverlay = false,
            overlayMessage = undefined
        ) {
            let req = {
                url: endpoint,
                method: 'GET',
                responseType: 'blob',
            }

            if (!hideOverlay) {
                this.startWorking(overlayMessage)
            }

            try {
                const response = await _axios(req)
                const data = response.data

                const url = window.URL.createObjectURL(data)
                const link = document.createElement('a')

                link.href = url
                link.setAttribute('download', filename ? filename : 'unknown')
                document.body.appendChild(link)
                link.click()
            } catch (error) {
                if (error?.type === 'system') {
                    switch (error.code) {
                        case 0: {
                            this.msgError(error.reason)
                            break
                        }
                        case 404: {
                            // router.replace({ name: 'not_found' })
                            break
                        }
                        case 1000: {
                            if (error.body.readonly) {
                                this.msgError(error.body.readonly[0])
                            } else {
                                this.msgError('Validation errors ocurred')
                                throw error
                            }
                            break
                        }
                        default: {
                            throw error
                        }
                    }
                } else {
                    throw {
                        type: 'server',
                        code: error?.status,
                        reason: `${error?.statusText}`,
                    }
                }
            } finally {
                if (!hideOverlay) {
                    this.finishWorking()
                }
            }
        },
        async $uploadBucketFile(
            endpoint,
            file,
            extra = undefined,
            overlay = true
        ) {
            if (overlay) {
                this.startWorking()
            }

            const form = new FormData()
            form.append('file', file)

            if (extra) {
                Object.entries(extra).forEach(([key, value]) => {
                    form.append(key, value)
                })
            }

            try {
                const response = await _axios({
                    method: 'POST',
                    url: endpoint,
                    data: form,
                })
                return response.data
            } catch {
                throw {
                    answer: 'error',
                    code: -1,
                    reason: 'Unknown error has ocurred',
                }
            } finally {
                if (overlay) {
                    this.finishWorking()
                }
            }
        },
    },
}
