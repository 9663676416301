export default [
    {
        name: 'MarketingActionCategoryList',
        path: 'marketing/categories',
        component: () => import('./views/MarketingActionCategoryList.vue'),
        meta: {
            title: 'Marketing Action Categories',
            perms: 'marketing',
            icon: 'fa-solid fa-tag',
        },
    },
    {
        name: 'MarketingActionCategoryCreate',
        path: 'marketing/categories/create',
        component: () => import('./views/MarketingActionCategoryForm.vue'),
    },
    {
        name: 'MarketingActionCategoryUpdate',
        path: 'marketing/categories/:category',
        component: () => import('./views/MarketingActionCategoryForm.vue'),
    },

    {
        name: 'MarketingActionList',
        path: 'marketing/actions',
        component: () => import('./views/MarketingActionList.vue'),
        meta: {
            title: 'Marketing Actions',
            perms: 'marketing',
            icon: 'fa-solid fa-comments',
        },
    },
    {
        name: 'MarketingActionCreate',
        path: 'marketing/actions/create',
        component: () => import('./views/MarketingActionForm.vue'),
    },
    {
        name: 'MarketingActionUpdate',
        path: 'marketing/actions/:action',
        component: () => import('./views/MarketingActionForm.vue'),
    },

    {
        name: 'ClientMarketingActionList',
        path: 'clients/:client/marketing/actions',
        component: () => import('./views/MarketingActionList.vue'),
    },
    {
        name: 'ClientMarketingActionCreate',
        path: 'clients/:client/marketing/actions/create',
        component: () => import('./views/MarketingActionForm.vue'),
    },
    {
        name: 'ClientMarketingActionUpdate',
        path: 'clients/:client/marketing/actions/:action',
        component: () => import('./views/MarketingActionForm.vue'),
    },

    {
        name: 'HotelMarketingActionList',
        path: 'hotels/:hotel/marketing/actions',
        component: () => import('./views/MarketingActionList.vue'),
    },
    {
        name: 'HotelMarketingActionCreate',
        path: 'hotels/:hotel/marketing/actions/create',
        component: () => import('./views/MarketingActionForm.vue'),
    },
    {
        name: 'HotelMarketingActionUpdate',
        path: 'hotels/:hotel/marketing/actions/:action',
        component: () => import('./views/MarketingActionForm.vue'),
    },
]
