import { defineStore } from 'pinia'

export const useUIStore = defineStore('ui', {
    state: () => ({
        working: false,
        message: '',
    }),
    actions: {
        startWorking(message = 'Fetching from the server') {
            this.working = true
            this.message = message
        },
        finishWorking() {
            this.working = false
            this.message = ''
        },
    },
})
