export default [
    {
        name: 'TicketList',
        path: 'tickets',
        component: () => import('./views/TicketList.vue'),
        meta: {
            title: 'Tickets',
            perms: 'leads',
            icon: 'fa-solid fa-list-check',
        },
    },
    {
        name: 'TicketMyList',
        path: 'tickets/my',
        component: () => import('./views/TicketList.vue'),
        meta: {
            title: 'My Tickets',
            perms: 'leads',
            icon: 'fa-solid fa-list-check',
        },
    },
    {
        name: 'TicketCreate',
        path: 'tickets/create',
        component: () => import('./views/TicketForm.vue'),
    },
    {
        name: 'TicketUpdate',
        path: 'tickets/:ticket',
        component: () => import('./views/TicketForm.vue'),
    },
]
