<template>
    <T3Modal
        ref="modal"
        title="Email Message"
        width="80%"
    >
        <p><strong>Subject: </strong>{{ message?.subject }}</p>
        <p><strong>From: </strong>{{ message?.from_email }}</p>
        <p><strong>To: </strong>{{ message?.to_email }}</p>
        <hr />
        <div
            v-if="(message?.attachments || []).length > 0"
            class="columns"
        >
            <div class="column is-narrow has-text-weight-bold mt-2">
                Attachments
            </div>
            <div class="column">
                <CRMEmailAttachments :model-value="message.attachments" />
            </div>
        </div>

        <iframe
            v-if="message?.html"
            ref="body"
            height="700px"
            width="100%"
            :srcdoc="message.body"
        />

        <template #footer>
            <div class="buttons">
                <T3Button
                    label="Close"
                    @clicked="onCloseClicked"
                />
            </div>
        </template>
    </T3Modal>
</template>

<script>
import CRMEmailAttachments from '@/modules/newcrm/components/CRMEmailAttachments.vue'

export default {
    name: 'T3EmailMessageModal',
    components: {
        CRMEmailAttachments,
    },
    data: () => ({
        messageId: undefined,
        message: undefined,
    }),
    methods: {
        async open(messageId) {
            this.messageId = messageId
            this.message = await this.$request(`/crm/emails/${messageId}`)
            this.$refs.modal.show()
        },
        onCloseClicked() {
            this.messageId = undefined
            this.$refs.modal.hide()
        },
    },
}
</script>
