import { defineStore } from 'pinia'

export const useRoutesStore = defineStore('routes', {
    state: () => ({
        routes: {},
    }),
    getters: {
        routeParams(state) {
            return (routeName) => {
                return state.routes[routeName]?.params
            }
        },
    },
    actions: {
        initialize(router) {
            router.getRoutes().forEach((x) => {
                this.routes[x.name] = {
                    path: x.path,
                    meta: x.meta,
                    params: x.path
                        .split('/')
                        .filter((x) => x.charAt(0) === ':')
                        .map((x) => x.substring(1)),
                }
            })
        },
    },
})
