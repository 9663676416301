<template>
    <div class="field has-addons">
        <T3DateField
            v-model="date"
            :label="label"
            :placeholder="datePlaceholder"
            :min-value="minDate"
            :max-value="maxDate"
            :errors="childErrors"
            :readonly="readonly"
        />
        <T3TimeField
            v-model="time"
            :class="{ timeLabel }"
            :placeholder="timePlaceholder"
            :errors="childErrors"
            :readonly="readonly"
        />

        <p
            v-for="error in errors"
            :key="error"
            class="help is-danger"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'

export default {
    name: 'T3DateTimeField',
    extends: Field,
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        datePlaceholder: {
            type: String,
            default: 'Date',
        },
        timePlaceholder: {
            type: String,
            default: 'Time',
        },
        minDate: {
            type: String,
            default: undefined,
        },
        maxDate: {
            type: String,
            default: undefined,
        },
    },
    emits: ['update:modelValue'],
    data: () => ({
        date: '',
        time: '',
    }),
    computed: {
        timeLabel() {
            return this.label !== undefined
        },
        datetime() {
            if (this.$isDate(this.date) && this.$isTime(this.time)) {
                return `${this.date} ${this.time}`
            }
            return ''
        },
        childErrors() {
            if (!this.$_.isEmpty(this.errors)) {
                return ['']
            }
            return undefined
        },
    },
    watch: {
        modelValue(newval) {
            this.fromValue(newval)
        },
        datetime(newval) {
            this.$emit('update:modelValue', newval)
        },
    },
    mounted() {
        this.fromValue(this.modelValue)
    },
    methods: {
        fromValue(value) {
            if (value && value.includes('T')) {
                const comps = value.split('T')
                const date = comps[0].split('-')
                this.date = `${date[2]}/${date[1]}/${date[0]}`
                this.time = comps[1]
            }
            if (this.$isDatetime(value)) {
                const comps = value.split(' ')
                this.date = comps[0]
                this.time = comps[1]
            }
        },
    },
}
</script>

<style scoped>
.timeLabel {
    padding-top: 18px;
}
</style>
