<template>
    <div
        class="modal"
        :class="{ 'is-active': active }"
    >
        <div
            v-if="!hideOverlay"
            class="modal-background"
        ></div>
        <div
            class="modal-card"
            :style="{ width, height }"
        >
            <header
                v-if="!hideHeader"
                class="modal-card-head"
                :style="{
                    'background-color': titleBlackgroundColor,
                }"
            >
                <p class="modal-card-title">
                    {{ title }}
                </p>
                <button
                    v-if="!hideCloseButton"
                    class="delete"
                    arial-label="close"
                    @click="onCloseButtonClosed"
                ></button>
            </header>
            <section
                class="modal-card-body"
                :class="bodyClass"
            >
                <slot></slot>
            </section>
            <footer
                v-if="!hideFooter"
                class="modal-card-foot"
            >
                <div class="columns">
                    <div class="column"></div>
                    <div class="column is-narrow">
                        <slot name="footer"></slot>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalWindow',
    props: {
        title: {
            type: String,
            default: '',
        },
        scrollable: {
            type: Boolean,
            default: false,
        },
        hideOverlay: {
            type: Boolean,
            default: false,
        },
        hideHeader: {
            type: Boolean,
            default: false,
        },
        hideFooter: {
            type: Boolean,
            default: false,
        },
        hideCloseButton: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: undefined,
        },
        height: {
            type: String,
            default: undefined,
        },
        isError: {
            type: Boolean,
            default: false,
        },
        hideOnEscape: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['opened', 'closed'],
    data: () => ({
        active: false,
    }),
    computed: {
        bodyClass() {
            if (this.scrollable) {
                return 'scrollable-body'
            }
            return 'fixed-body'
        },
        titleBlackgroundColor() {
            if (this.isError) {
                return '#D9534F'
            }
            return '#EA621F'
        },
    },
    mounted() {
        if (this.hideOnEscape) {
            document.body.addEventListener('keyup', (e) => {
                if (e.key === 'Enter') {
                    this.hide()
                }
            })
        }
    },
    methods: {
        show() {
            this.active = true
            this.$emit('opened')
        },
        hide() {
            this.active = false
        },
        onCloseButtonClosed() {
            this.hide()
            this.$emit('closed')
        },
    },
}
</script>

<style scoped>
.modal-card {
    overflow: visible !important;
}
.modal-card-body {
    padding-bottom: 40px;
}
.modal-card-head {
    padding: 10px 20px;
}
.modal-card-title {
    font-size: medium;
    font-weight: bold;
    color: #eeeeee;
}
.modal-card-foot .columns {
    width: 100%;
}
.fixed-body {
    overflow: visible !important;
}
.scrollable-body {
    max-height: 600px;
    overflow-y: auto;
}
</style>
