<template>
    <div
        :class="{ endpoint }"
        @click="onClicked"
    >
        <span class="icon is-small mr-1">
            <i :class="$icon"></i>
        </span>
        <span>{{ $filename }}</span>
    </div>
</template>

<script>
const MIME_TO_ICON = {
    // Common Types
    'application/pdf': 'fa-solid fa-file-pdf',
    'text/csv': 'fa-solid fa-file-csv',
    'text/plain': 'fa-solid fa-file-lines',

    // Office Types
    'application/msword': 'fa-solid fa-file-word',
    'application/rtf': 'fa-solid fa-file-word',
    'application/vnd.ms-excel': 'fa-solid fa-file-excel',
    'application/vnd.ms-powerpoint': 'fa-solid fa-file-powerpoint',
    'application/vnd.oasis.opendocument.presentation':
        'fa-solid fa-file-powerpoint',
    'application/vnd.oasis.opendocument.spreadsheet': 'fa-solid fa-file-excel',
    'application/vnd.oasis.opendocument.text': 'fa-solid fa-file-word',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        'fa-solid fa-file-powerpoint',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        'fa-solid fa-file-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        'fa-solid fa-file-word',

    // Archive Types
    'application/gzip': 'fa-solid fa-file-zipper',
    'application/vnd.rar': 'fa-solid fa-file-zipper',
    'application/x-7z-compressed': 'fa-solid fa-file-zipper',
    'application/x-bzip': 'fa-solid fa-file-zipper',
    'application/x-bzip2': 'fa-solid fa-file-zipper',
    'application/x-tar': 'fa-solid fa-file-zipper',
    'application/zip': 'fa-solid fa-file-zipper',

    // Audio Types
    'application/x-cdf': 'fa-solid fa-file-audio',
    'audio/3gpp': 'fa-solid fa-file-audio',
    'audio/3gpp2': 'fa-solid fa-file-audio',
    'audio/aac': 'fa-solid fa-file-audio',
    'audio/midi': 'fa-solid fa-file-audio',
    'audio/mpeg': 'fa-solid fa-file-audio',
    'audio/ogg': 'fa-solid fa-file-audio',
    'audio/opus': 'fa-solid fa-file-audio',
    'audio/wav': 'fa-solid fa-file-audio',
    'audio/webm': 'fa-solid fa-file-audio',
    'audio/x-midi': 'fa-solid fa-file-audio',

    // Video Types
    'video/3gpp': 'fa-solid fa-file-video',
    'video/3gpp2': 'fa-solid fa-file-video',
    'video/mp2t': 'fa-solid fa-file-video',
    'video/mp4': 'fa-solid fa-file-video',
    'video/mpeg': 'fa-solid fa-file-video',
    'video/ogg': 'fa-solid fa-file-video',
    'video/webm': 'fa-solid fa-file-video',
    'video/x-msvideo': 'fa-solid fa-file-video',

    //Images Types
    'image/bmp': 'fa-solid fa-file-image',
    'image/gif': 'fa-solid fa-file-image',
    'image/jpeg': 'fa-solid fa-file-image',
    'image/png': 'fa-solid fa-file-image',
    'image/svg+xml': 'fa-solid fa-file-image',
    'image/tiff': 'fa-solid fa-file-image',
    'image/vnd.microsoft.icon': 'fa-solid fa-file-image',
    'image/webp': 'fa-solid fa-file-image',

    // Code Types
    'application/atom+xml': 'fa-solid fa-file-code',
    'application/json': 'fa-solid fa-file-code',
    'application/ld+json': 'fa-solid fa-file-code',
    'application/x-httpd-php': 'fa-solid fa-file-code',
    'application/x-python-code': 'fa-solid fa-file-code',
    'application/x-sh': 'fa-solid fa-file-code',
    'application/xhtml+xml': 'fa-solid fa-file-code',
    'application/xml': 'fa-solid fa-file-code',
    'text/css': 'fa-solid fa-file-code',
    'text/html': 'fa-solid fa-file-code',
    'text/javascript': 'fa-solid fa-file-code',
    'text/x-python': 'fa-solid fa-file-code',
    'text/xml': 'fa-solid fa-file-code',
}

export default {
    props: {
        filename: {
            type: String,
            required: true,
        },
        mimetype: {
            type: String,
            default: undefined,
        },
        endpoint: {
            type: String,
            default: undefined,
        },
        maxChars: {
            type: Number,
            default: 16,
        },
    },
    emits: ['click'],
    computed: {
        $icon() {
            if (this.mimetype) {
                return MIME_TO_ICON[this.mimetype] || 'fa-solid fa-file'
            }
            return 'fa-solid fa-file'
        },
        $filename() {
            if ((this.filename?.length || 0) > this.maxChars) {
                const split = this.filename.split('.')
                if (split.length > 1) {
                    const ext = split[split.length - 1]
                    const name = split
                        .slice(0, split.length - 1)
                        .join('.')
                        .slice(0, this.maxChars - ext.length - 3)
                    return `${name}...${ext}`
                } else {
                    return this.filename.slice(0, this.maxChars)
                }
            }
            return this.filename
        },
    },
    methods: {
        async onClicked() {
            if (this.endpoint) {
                await this.$downloadBucketFile(this.endpoint, this.filename)
            } else {
                this.$emit('click')
            }
        },
    },
}
</script>

<style scoped>
.endpoint {
    cursor: pointer;
}
</style>
