export const twoZeroPad = function (number) {
    return number.toString().padStart(2, '0')
}

export const parseNumber = function (value) {
    const result = Number(value)
    if (Number.isNaN(result)) {
        return 0.0
    }
    return result
}
