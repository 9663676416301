<template>
    <div class="centered">
        <span :class="langFlag(modelValue)"></span>
    </div>
</template>
<script>
import { langFlag } from '@/helpers/i18n'

export default {
    props: {
        modelValue: {
            type: String,
            default: undefined,
        },
    },
    methods: {
        langFlag,
    },
}
</script>

<style scoped>
.centered {
    text-align: center;
}
.fib {
    margin: 0 1px;
    zoom: 150%;
}
</style>
