<template>
    <T3TableView
        v-if="endpoint"
        :title="title"
        :endpoint="endpoint"
        :fields="[
            {
                id: 'pk',
                label: 'ID',
                type: 'int',
                width: '5em',
            },
            {
                id: 'from_email',
                label: 'From Email',
            },
            {
                id: 'to_email',
                label: 'To Email',
            },
            {
                id: 'subject',
                label: 'Subject',
            },
            {
                id: 'send_at',
                label: 'Send At',
                type: 'datetime',
                align: 'center',
            },
            {
                id: 'user',
                label: 'User',
            },
        ]"
        :item-actions="[
            {
                action: 'show',
                icon: 'fas fa-external-link-alt',
                tooltip: 'Show',
                role: 'info',
            },
        ]"
        :inline="title === undefined"
        @show="onShowClicked"
        @aggregates-fetched="onAggregatesFetched"
    />
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: undefined,
        },
        endpoint: {
            type: String,
            default: undefined,
        },
    },
    emits: ['countFetched'],
    methods: {
        onAggregatesFetched({ count }) {
            this.$emit('countFetched', count > 0 ? count : undefined)
        },
    },
}
</script>
