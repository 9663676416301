<template>
    <div class="field">
        <label
            v-if="label !== undefined"
            class="label is-small"
        >
            {{ labelText }}
        </label>

        <div class="field">
            <div
                class="file is-small has-name"
                :class="{ 'is-danger': errors.length > 0 }"
            >
                <label class="file-label">
                    <input
                        ref="input"
                        class="file-input"
                        type="file"
                        name="resume"
                        :accept="accept"
                        :readonly="readonly"
                        @change="onFileChange"
                    />
                    <span class="file-cta">
                        <span class="file-icon">
                            <i class="fa-solid fa-upload"></i>
                        </span>
                        <span class="file-label">{{ buttonLabel }}</span>
                    </span>
                    <span
                        v-if="filename"
                        class="file-name"
                    >
                        {{ filename }}
                    </span>

                    <button
                        v-if="modelValue"
                        class="button is-info is-small"
                        @click="onFileDownload"
                    >
                        <span class="icon is-small">
                            <i class="fa-solid fa-download"></i>
                        </span>
                    </button>
                </label>
            </div>
        </div>

        <p
            v-for="error in errors"
            :key="error"
            class="help is-danger"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'

export default {
    name: 'T3BucketFileField',
    extends: Field,
    props: {
        modelValue: {
            type: Object,
            default: null,
        },
        buttonLabel: {
            type: String,
            default: 'Upload',
        },
        accept: {
            type: String,
            default: undefined,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    computed: {
        filename() {
            return this.modelValue?.filename
        },
    },
    methods: {
        async onFileChange(event) {
            const file = event.target.files[0]
            const response = await this.$uploadFile(file)
            this.$emit('update:modelValue', response)
        },
        onFileDownload() {
            const bf = this.modelValue
            this.$download(bf.bucket, bf.uid, bf.filename)
        },
    },
}
</script>
