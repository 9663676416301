import { defineStore } from 'pinia'
import short from 'short-uuid'

export const useMessagesStore = defineStore('messages', {
    state: () => ({
        messages: [],
    }),
    actions: {
        pushMessage(payload) {
            const uid = short.generate()
            payload.id = uid
            payload.time = Date.now()
            this.messages.push(payload)

            setTimeout(() => {
                this.removeOldMessages()
            }, 5000)

            return uid
        },
        removeMessage({ id }) {
            this.messages = this.messages.filter((msg) => msg.id !== id)
        },
        removeOldMessages() {
            const now = Date.now()
            this.messages = this.messages.filter((msg) => now - msg.time < 5000)
        },
        msgSuccess(message) {
            this.pushMessage({
                role: 'success',
                content: message,
            })
        },
        msgInfo(message) {
            this.pushMessage({
                role: 'info',
                content: message,
            })
        },
        msgWarning(message) {
            this.pushMessage({
                role: 'warning',
                content: message,
            })
        },
        msgError(message) {
            this.pushMessage({
                role: 'danger',
                content: message,
            })
        },
    },
})
