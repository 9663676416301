<template>
    <T3Control
        :label="label"
        :size="size"
        :control-classes="['is-expanded']"
        :field-classes="['has-addons']"
    >
        <input
            v-model="$search"
            class="input is-small"
            :class="{
                'is-danger': errors ? true : false,
            }"
            type="text"
            :placeholder="placeholder"
            @keyup.enter="requestSearch"
            @keyup.esc="clearSearch"
        />
        <template #extra>
            <div class="control">
                <a
                    :class="buttonClasses"
                    :disabled="!isEnabled"
                    :style="{ backgroundColor: buttonColor }"
                    @click="requestSearch"
                >
                    <span :class="buttonIconClasses">
                        <i class="fa fa-search"></i>
                    </span>
                    Search
                </a>
            </div>
        </template>
    </T3Control>
</template>

<script>
import Control from './Control'

export default {
    name: 'T3SearchField',
    extends: Control,
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        role: {
            type: String,
            default: 'primary',
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue', 'searchRequested', 'searchCleared'],
    computed: {
        buttonClasses() {
            let result = ['button', 'is-small']
            if (this.role) {
                result.push(`is-${this.role}`)
            }
            return result
        },
        buttonIconClasses() {
            return ['icon', 'is-small']
        },
        isEnabled() {
            return this.modelValue.trim() ? true : false
        },
    },
    watch: {
        $search: {
            get() {
                return this.modelValue
            },
            set(newval) {
                this.$emit('update:modelValue', newval)
            },
            buttonColor: {
                type: String,
                default: undefined,
            },
        },
    },
    methods: {
        requestSearch() {
            if (this.isEnabled) {
                this.$emit('searchRequested', this.modelValue)
            }
        },
        clearSearch() {
            this.$emit('update:modelValue', '')
            this.$emit('searchCleared')
        },
    },
}
</script>
