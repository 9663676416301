<template>
    <canvas ref="canvas"></canvas>
</template>

<script>
import Chart from 'chart.js/auto'

var charts = {}

export default {
    props: {
        labels: {
            type: Array,
            default: undefined,
        },
        datasets: {
            type: Array,
            required: true,
        },
        borderWidth: {
            type: Number,
            default: 1,
        },
        yLabel: {
            type: String,
            default: undefined,
        },
        ySuffix: {
            type: String,
            default: '',
        },
    },
    watch: {
        labels(newval) {
            const uid = this.$.uid
            charts[uid].data.labels = newval.map((x) => x[1])
            charts[uid].update()
        },

        datasets(newval) {
            const uid = this.$.uid
            charts[uid].data.datasets = newval
            charts[uid].update()
        },
    },
    mounted() {
        const ctx = this.$refs.canvas

        const data = {
            labels: this.labels.map((x) => x[1]),
            datasets: this.datasets,
        }

        const options = {
            responsive: true,
            plugins: {
                legend: {
                    display: this.label !== undefined,
                },
            },
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                    title: {
                        display: this.yLabel !== undefined,
                        text: this.yLabel,
                    },
                    beginAtZero: true,
                    ticks: {
                        callback: (value) => `${value}${this.ySuffix}`,
                    },
                },
            },
        }

        const uid = this.$.uid

        charts[uid] = new Chart(ctx, {
            type: 'bar',
            data,
            options,
        })
    },
}
</script>
