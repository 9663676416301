<template>
    <div
        :class="{
            'mr-2': true,
            dropdown: true,
            'is-hoverable': hoverable,
            'is-active': active,
        }"
        @click="active = !active"
    >
        <div class="dropdown-trigger">
            <button
                class="button"
                aria-haspopup="true"
                aria-controls="dropdown-menu"
            >
                <span>{{ label }}</span>
                <span class="icon is-small">
                    <i class="fas fa-angle-down"></i>
                </span>
            </button>
        </div>
        <div
            id="dropdown-menu"
            class="dropdown-menu"
            role="menu"
        >
            <div class="dropdown-content">
                <template
                    v-for="(action, idx) in $actions"
                    :key="idx"
                >
                    <a
                        v-if="action.type === 'action'"
                        class="dropdown-item"
                        @click="onActionClicked(action)"
                    >
                        {{ action.label }}
                    </a>

                    <router-link
                        v-else-if="action.type === 'link'"
                        :to="action.route"
                        class="dropdown-item"
                    >
                        <span
                            v-if="action.icon"
                            class="icon is-small mr-2"
                        >
                            <i :class="action.icon"></i>
                        </span>
                        {{ action.label }}
                    </router-link>

                    <hr
                        v-else-if="action.type === 'divider'"
                        class="dropdown-divider"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        actions: {
            type: Array,
            default: () => [],
        },
        hoverable: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        active: false,
    }),
    computed: {
        $actions() {
            return this.actions.map((action) => ({
                type: action.type || 'action',
                action: action.action,
                label: action.label,
                icon: action.icon,
                route: action.route,
                payload: action.payload,
            }))
        },
    },
    methods: {
        onActionClicked(action) {
            this.$emit(action.action, action.payload)
        },
    },
}
</script>
