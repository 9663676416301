import Concept from './Concept.vue'
import FileName from './FileName.vue'
import Modification from './Modification.vue'
import PanelButton from './PanelButton.vue'
import StarsRating from './StarsRating.vue'

export default function (app) {
    app.component('T3Concept', Concept)
    app.component('T3FileName', FileName)
    app.component('T3Modification', Modification)
    app.component('T3PanelButton', PanelButton)
    app.component('T3Stars', StarsRating)
}
