class AutocompleteClient {
    constructor() {
        this.baseUrl = import.meta.env.VITE_APP_AUTOCMP_BACKEND
    }

    get headers() {
        const headers = {
            Accept: 'application/json',
        }

        return headers
    }

    async read(table, id) {
        if (id) {
            const resource = `${this.baseUrl}/${table}/${id}`
            const response = await fetch(resource, {
                method: 'GET',
                headers: this.headers,
            })
            return await response.json()
        }
        return undefined
    }

    async search(table, query, filters = undefined) {
        let params = {
            q: query,
        }

        if (filters) {
            params = {
                ...params,
                ...filters,
            }
        }

        const urlParams = new URLSearchParams(params).toString()

        const resource = `${this.baseUrl}/${table}?${urlParams}`
        const response = await fetch(resource, {
            method: 'GET',
            headers: this.headers,
        })
        return await response.json()
    }

    async hit(table, id) {
        if (id) {
            const resource = `${this.baseUrl}/${table}/${id}`
            const response = await fetch(resource, {
                method: 'POST',
                headers: this.headers,
            })
            return await response.json()
        }

        return undefined
    }
}

export const createAutocompleteClient = function () {
    return new AutocompleteClient()
}

export default {
    createAutocompleteClient,
}
