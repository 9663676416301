import AddressTab from './AddressTab.vue'
import DocumentsTab from './DocumentsTab.vue'
import RoomTypes from './RoomTypes.vue'
import RedsysForm from './RedsysForm.vue'
import SocialTab from './SocialTab.vue'

export default function (app) {
    app.component('T3AddressTab', AddressTab)
    app.component('T3DocumentsTab', DocumentsTab)
    app.component('T3RoomTypes', RoomTypes)
    app.component('T3RedsysForm', RedsysForm)
    app.component('T3SocialTab', SocialTab)
}
