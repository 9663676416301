<template>
    <button
        class="w-full bg-primary text-[#111827] dark:text-white py-2 rounded cursor-pointer bg-transparent border-solid border-[1px] border-stroke dark:border-[#4B5563] hover:bg-[#e0dbdb] hover:dark:bg-[#2f3947] duration-100"
        :title="modelValue.filename"
        @click="download"
    >
        <T3FileName
            :filename="modelValue.filename"
            :mimetype="modelValue.mime_type"
        />
    </button>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
    },
    methods: {
        async download() {
            event.stopPropagation()
            const uid = this.modelValue.uid
            const filename = this.modelValue.filename
            const url = `/crm/emails/attachments/${uid}/download`
            await this.$downloadBucketFile(url, filename)
        },
    },
}
</script>
