import setupSpecificComponents from './specific'

import Control from './Control.vue'

import Button from './Button.vue'
import ButtonLink from './ButtonLink.vue'
import DownloadButton from './DownloadButton.vue'
import ToggleButton from './ToggleButton.vue'

import MonthCalendar from './MonthCalendar.vue'
import OldCalendar from './OldCalendar.vue'
import FileField from './FileField.vue'
import SearchField from './SearchField.vue'
import StaticField from './StaticField.vue'

import TranslatedTextSection from './TranslatedTextSection.vue'

export default function (app) {
    setupSpecificComponents(app)

    app.component('T3Control', Control)

    app.component('T3Button', Button)
    app.component('T3ButtonLink', ButtonLink)
    app.component('T3DownloadButton', DownloadButton)
    app.component('T3ToggleButton', ToggleButton)

    app.component('T3SearchField', SearchField)
    app.component('T3FileField', FileField)
    app.component('T3StaticField', StaticField)
    app.component('T3MonthCalendar', MonthCalendar)
    app.component('T3OldCalendar', OldCalendar)

    app.component('T3TranslatedTextSection', TranslatedTextSection)
}
