<template>
    <div class="field">
        <label
            v-if="label !== undefined"
            class="label is-small"
        >
            {{ labelText }}
        </label>
        <div class="control has-icons-left">
            <input
                v-bind="$attrs"
                type="text"
                class="input is-small"
                :class="inputClasses"
                :value="modelValue"
                :disabled="readonly"
                @input="onInput"
                @blur="onBlur"
            />
            <span class="icon is-left is-small">
                <i class="fas fa-clock"></i>
            </span>
        </div>
        <p
            v-for="error in errors"
            :key="error"
            class="help is-danger"
        >
            {{ error }}
        </p>
    </div>
</template>

<script>
import Field from './Field'

const CHARSET = new Set(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':'])

export default {
    name: 'T3TimeField',
    extends: Field,
    props: {
        modelValue: {
            type: String,
            default: '',
        },
    },
    emits: ['update:modelValue'],
    methods: {
        onInput(event) {
            if (this.isCharsetComplaint(event.target.value)) {
                this.$emit('update:modelValue', event.target.value)
            } else {
                event.target.value = this.modelValue
            }
        },
        onBlur(event) {
            const cleaned = this.cleanedFormat(this.modelValue)
            if (cleaned !== this.modelValue) {
                event.target.value = cleaned
                this.$emit('update:modelValue', cleaned)
            }
        },
        isCharsetComplaint(val) {
            let colons = 0
            if (val) {
                for (let c of val) {
                    if (!CHARSET.has(c)) {
                        return false
                    }
                    if (c === ':') {
                        colons += 1
                    }
                }
            }
            return colons < 2
        },
        cleanedFormat(val) {
            if (val === '' || val === null) {
                return val
            }
            const comps = val.split(':')
            if (comps.length === 1) {
                if (
                    comps[0].length === 4 &&
                    Number(comps[0].substr(0, 2)) <= 23 &&
                    Number(comps[0].substr(2, 2)) <= 59
                ) {
                    return comps[0].substr(0, 2) + ':' + comps[0].substr(2, 2)
                } else {
                    const total_minutes = Number(comps[0])
                    const hours = Math.floor(total_minutes / 60.0)
                    if (hours <= 23) {
                        const minutes = total_minutes % 60
                        return `${this.$twoZeroPad(hours)}:${this.$twoZeroPad(
                            minutes
                        )}`
                    } else {
                        return '00:00'
                    }
                }
            } else {
                const comp1 = Number(comps[0])
                const comp2 = Number(comps[1])
                if (comp1 <= 23 && comp2 <= 59) {
                    return `${this.$twoZeroPad(comp1)}:${this.$twoZeroPad(
                        comp2
                    )}`
                } else {
                    const hours = comp1 + Math.floor(comp2 / 60.0)
                    const minutes = comp2 % 60
                    if (hours <= 23) {
                        return `${this.$twoZeroPad(hours)}:${this.$twoZeroPad(
                            minutes
                        )}`
                    }
                    return `00:${this.$twoZeroPad(minutes)}`
                }
            }
        },
    },
}
</script>

<style scoped>
input {
    min-width: 5rem;
    max-width: 5rem;
}
</style>
