<template>
    <T3TabPage
        label="Notes"
        icon="fa fa-clipboard"
        :badge="notesCount"
    >
        <div
            v-if="addEndpoint"
            class="columns"
        >
            <div class="column">
                <T3RichTextAreaField
                    v-model="note"
                    bordered
                />
            </div>
            <div class="column is-narrow pt-2">
                <T3Button
                    :disabled="!note.trim()"
                    role="primary"
                    label="Add Note"
                    @clicked="onAddNoteClicked"
                />
            </div>
        </div>
        <div
            v-for="_note in notes"
            :key="_note.timestamp"
            class="box"
        >
            <div class="columns">
                <div class="column">
                    <span class="icon is-small">
                        <i class="fas fa-user"></i>
                    </span>
                    <router-link
                        :to="{
                            name: 'UserUpdate',
                            params: {
                                user: _note.user.pk,
                            },
                        }"
                    >
                        {{ _note.user.label }}
                    </router-link>
                </div>
                <div class="column is-narrow">
                    <span class="icon is-small">
                        <i class="fas fa-clock"></i>
                    </span>
                    {{ _note.timestamp }}
                </div>
            </div>

            <div class="columns">
                <div
                    class="column"
                    v-html="note.note"
                ></div>
            </div>
        </div>
    </T3TabPage>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
        addEndpoint: {
            type: String,
            default: undefined,
        },
    },
    data: () => ({
        note: '',
    }),
    computed: {
        notes() {
            return this.modelValue?.notes || []
        },
        notesCount() {
            if (this.modelValue?.notes_count) {
                return `${this.modelValue?.notes_count}`
            }
            return undefined
        },
    },
    methods: {
        async onAddNoteClicked() {
            const { notes, notes_count } = await this.$request(
                this.addEndpoint,
                {
                    note: this.note,
                }
            )
            this.modelValue.notes = notes
            this.modelValue.notes_count = notes_count
            this.note = ''
        },
    },
}
</script>
