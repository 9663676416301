import PaymentRoutes from './modules/payments/routes'
import InvoicingRoutes from './modules/invoicing/routes'

export default [
    {
        name: 'LeadList',
        path: 'leads',
        component: () => import('./views/LeadList.vue'),
        meta: {
            title: 'All',
            perms: 'leads',
        },
    },
    {
        name: 'CurrentLeadList',
        path: 'leads/current',
        component: () => import('./views/LeadList.vue'),

        meta: {
            title: 'Current',
            perms: ['leads', 'accommodation'],
            filters: {
                status__in: ['0', 'Q', '1', '2', '3', '4', '5'],
            },
            ignoreStatuses: ['6', '7'],
        },
    },
    {
        name: 'NewAndRecentLeadList',
        path: 'leads/recent',
        component: () => import('./views/LeadListTabbed.vue'),
        meta: {
            title: 'New and Recent',
            perms: 'leads',
            filters: {
                status__in: ['0', 'Q'],
            },
            hideStatuses: true,
        },
    },
    {
        name: 'QuotedLeadList',
        path: 'leads/quoted',
        component: () => import('./views/LeadList.vue'),
        meta: {
            title: 'Quoted',
            perms: 'leads',
            filters: {
                status: 'Q',
            },
            hideStatuses: true,
        },
    },
    {
        name: 'PreconfirmedLeadList',
        path: 'leads/preconfirmed',
        component: () => import('./views/LeadList.vue'),
        meta: {
            title: 'Preconfirmed',
            perms: 'leads',
            filters: {
                status: '1',
            },
            hideStatuses: true,
        },
    },
    {
        name: 'OnOptionLeadList',
        path: 'leads/onoption',
        component: () => import('./views/LeadList.vue'),
        meta: {
            title: 'On Option',
            perms: 'leads',
            filters: {
                status: '2',
            },
            hideStatuses: true,
        },
    },
    {
        name: 'OnModificationsLeadList',
        path: 'leads/onmodifications',
        component: () => import('./views/LeadList.vue'),
        meta: {
            title: 'On Modifications',
            perms: 'leads',
            filters: {
                status: '3',
            },
            hideStatuses: true,
        },
    },
    {
        name: 'ConfirmedLeadList',
        path: 'leads/confirmed',
        component: () => import('./views/LeadList.vue'),
        meta: {
            title: 'Confirmed',
            perms: 'leads',
            filters: {
                status: '4',
            },
            hideStatuses: true,
        },
    },
    {
        name: 'PaidLeadList',
        path: 'leads/paid',
        component: () => import('./views/LeadList.vue'),
        meta: {
            title: 'Paid',
            perms: 'leads',
            filters: {
                status: '5',
            },
            hideStatuses: true,
        },
    },
    {
        name: 'CancelTomorrowLeadList',
        path: 'leads/canceltomorrow',
        component: () => import('./views/LeadList.vue'),
        meta: {
            title: 'Cancel Tomorrow',
            perms: 'leads',
            filters: {
                status: '2',
                date$client_deadline: '$tomorrow',
            },
            hideStatuses: true,
        },
    },
    {
        name: 'AboutToCancelLeadList',
        path: 'leads/abouttocancel',
        component: () => import('./views/LeadList.vue'),
        meta: {
            title: 'About to Cancel',
            perms: 'leads',
            filters: {
                status: '6',
                about_to_cancel_at__isnull: true,
            },
            hideStatuses: true,
        },
    },
    {
        name: 'CancelledLeadList',
        path: 'leads/cancelled',
        component: () => import('./views/LeadList.vue'),
        meta: {
            title: 'Cancelled',
            perms: 'leads',
            filters: {
                status: '6',
                about_to_cancel_at__isnull: true,
            },
            hideStatuses: true,
        },
    },
    {
        name: 'ClosedLeadList',
        path: 'leads/closed',
        component: () => import('./views/LeadList.vue'),
        meta: {
            title: 'Closed',
            perms: 'leads',
            filters: {
                status: '7',
            },
            hideStatuses: true,
        },
    },
    {
        name: 'FITLeadList',
        path: 'leads/fit',
        component: () => import('./views/LeadList.vue'),
        meta: {
            title: 'FIT',
            perms: 'leads',
            filters: {
                fit: true,
            },
            hideStatuses: true,
        },
    },
    {
        name: 'LeadCreate',
        path: 'leads/create',
        component: () => import('./views/LeadCreate.vue'),
    },
    {
        name: 'LeadCalendar',
        path: 'leads/calendar',
        component: () => import('./views/LeadCalendar.vue'),
        meta: {
            title: 'Calendar',
            perms: 'leads',
            icon: 'fa-solid fa-calendar-alt',
        },
    },

    {
        name: 'LeadModificationList',
        path: 'leads/modifications',
        component: () => import('./views/LeadModificationList.vue'),
        meta: {
            title: 'Modifications',
            perms: 'leads',
            icon: 'fa-solid fa-clipboard-list',
        },
    },
    {
        name: 'LeadUpdate',
        path: 'leads/:lead',
        component: () => import('./views/LeadUpdate.vue'),
    },
    {
        name: 'LeadHotelEmails',
        path: 'leads/:lead/hotels/emails',
        component: () => import('./views/LeadHotelsEmails.vue'),
    },
    {
        name: 'TourList',
        path: 'tours',
        component: () => import('./views/TourList.vue'),
        meta: {
            title: 'All',
            perms: 'leads',
        },
    },
    {
        name: 'CurrentTourList',
        path: 'tours/current',
        component: () => import('./views/TourList.vue'),
        meta: {
            title: 'Current',
            perms: 'leads',
            filters: {
                status__in: ['0', 'Q', '1', '2', '3', '4', '5'],
            },
            ignoreStatuses: ['6', '7'],
        },
    },
    {
        name: 'NewAndRecentTourList',
        path: 'tours/recent',
        component: () => import('./views/TourList.vue'),
        meta: {
            title: 'New and Recent',
            perms: 'leads',
            filters: {
                status: '0',
                quotes_sent: 0,
            },
            hideStatuses: true,
        },
    },
    {
        name: 'QuotedTourList',
        path: 'tours/quoted',
        component: () => import('./views/TourList.vue'),
        meta: {
            title: 'Quoted',
            perms: 'leads',
            filters: {
                status: 'Q',
            },
            hideStatuses: true,
        },
    },
    {
        name: 'PreconfirmedTourList',
        path: 'tours/preconfirmed',
        component: () => import('./views/TourList.vue'),
        meta: {
            title: 'Pre Confirmed',
            perms: 'leads',
            filters: {
                status: '1',
            },
            hideStatuses: true,
        },
    },
    {
        name: 'OnOptionTourList',
        path: 'tours/onoption',
        component: () => import('./views/TourList.vue'),
        meta: {
            title: 'On Option',
            perms: 'leads',
            filters: {
                status: '2',
            },
            hideStatuses: true,
        },
    },
    {
        name: 'OnModificationsTourList',
        path: 'tours/onmodifications',
        component: () => import('./views/TourList.vue'),
        meta: {
            title: 'On Modifications',
            perms: 'leads',
            filters: {
                status: '3',
            },
            hideStatuses: true,
        },
    },
    {
        name: 'ConfirmedTourList',
        path: 'tours/confirmed',
        component: () => import('./views/TourList.vue'),
        meta: {
            title: 'Confirmed',
            perms: 'leads',
            filters: {
                status: '4',
            },
            hideStatuses: true,
        },
    },
    {
        name: 'PaidTourList',
        path: 'tours/paid',
        component: () => import('./views/TourList.vue'),
        meta: {
            title: 'Paid',
            perms: 'leads',
            filters: {
                status: '5',
            },
            hideStatuses: true,
        },
    },
    {
        name: 'CancelledTourList',
        path: 'tours/cancelled',
        component: () => import('./views/TourList.vue'),
        meta: {
            title: 'Cancelled',
            perms: 'leads',
            filters: {
                status: '6',
            },
            hideStatuses: true,
        },
    },
    {
        name: 'ClosedTourList',
        path: 'tours/closed',
        component: () => import('./views/TourList.vue'),
        meta: {
            title: 'Closed',
            perms: 'leads',
            filters: {
                status: '7',
            },
            hideStatuses: true,
        },
    },
    {
        name: 'TourCreate',
        path: 'tours/create',
        component: () => import('./views/TourCreate.vue'),
    },
    {
        name: 'TourUpdate',
        path: 'tours/:tour',
        component: () => import('./views/TourUpdate.vue'),
    },

    {
        name: 'QuoteList',
        path: 'quotes',
        component: () => import('./views/QuoteList.vue'),
        meta: {
            title: 'Quotes',
            perms: 'leads',
        },
    },
    {
        name: 'LeadQuoteUpdate',
        path: 'quotes/:quote',
        component: () => import('./views/QuoteUpdate.vue'),
    },

    {
        name: 'LeadIncomingPayment',
        path: 'leads/:lead/payments/incoming/:payment',
        component: () => import('./views/LeadIncomingPayment.vue'),
        meta: { noAuth: true },
    },
    {
        name: 'LeadPaymentCompleted',
        path: 'leads/:lead/payment/:order/completed',
        component: () => import('./views/LeadPaymentCompleted.vue'),
        meta: { noAuth: true },
    },
    {
        name: 'LeadPaymentCancelled',
        path: 'leads/:lead/payment/:order/cancelled',
        component: () => import('./views/LeadPaymentCancelled.vue'),
        meta: { noAuth: true },
    },
    {
        name: 'SeriesCreate',
        path: 'series/create',
        component: () => import('./views/SeriesCreate.vue'),
        meta: {
            title: 'Create Series',
            perms: 'leads',
        },
    },
    {
        name: 'LocationRatesCalendar',
        path: 'rates/by_location',
        component: () => import('./views/LocationRatesCalendar.vue'),
        meta: {
            title: 'Location Rates Calendar',
            icon: 'fa-solid fa-calendar-alt',
            perms: 'leads',
        },
    },
].concat(PaymentRoutes, InvoicingRoutes)
