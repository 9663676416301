<template>
    <div class="is-unselectable">
        <router-link :to="route">
            <div
                id="icon"
                class="icon-box hvr-outline-out"
            >
                <span class="icon is-large fa-2x">
                    <i :class="icon"></i>
                </span>
            </div>
        </router-link>
        <router-link :to="route">
            <div class="card">
                <header>
                    <p class="card-header-title">
                        {{ label }}
                    </p>
                </header>
                <div class="card-content">
                    <div class="columns">
                        <div
                            v-if="leftConcept !== undefined"
                            class="column is-6"
                        >
                            <div class="left-concept">
                                <div>
                                    {{ leftConcept }}
                                </div>
                                <div
                                    v-if="leftValue !== undefined"
                                    class="left-value"
                                >
                                    {{ leftValue }}
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="rightConcept !== undefined"
                            class="column is-6"
                        >
                            <div class="right-concept">
                                <div>
                                    {{ rightConcept }}
                                </div>
                                <div
                                    v-if="rightValue !== undefined"
                                    class="right-value"
                                >
                                    {{ rightValue }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        route: {
            type: Object,
            required: true,
        },
        leftConcept: {
            type: String,
            default: undefined,
        },
        leftValue: {
            type: String,
            default: undefined,
        },
        rightConcept: {
            type: String,
            default: undefined,
        },
        rightValue: {
            type: String,
            default: undefined,
        },
    },
}
</script>

<style scoped>
.card {
    text-align: end;
    border-radius: 2px;
    margin-bottom: 15px;
    cursor: pointer;
}

.card-header-title {
    color: #ea621f;
    padding-top: 25px;
}

#icon {
    position: relative;
    top: 20px;
    left: 20px;
    background: #ea621f;
    width: 70px;
    height: 60px;
    text-align: center;
    z-index: 1;
    box-shadow:
        5px 5px 20px 3px #b5b5b5,
        0 0 0 1px #dbdbdb;
    border-radius: 3px;
    cursor: pointer;
}

.icon-box .icon {
    position: relative !important;
    top: 10px !important;
    color: white !important;
}

.card-content {
    padding-top: 7px;
    padding-bottom: 19px;
}

.left-concept {
    text-align: left;
    font-size: 12pt;
}

.right-concept {
    font-size: 12pt;
    text-align: right;
}

.left-value {
    text-align: left !important;
    font-size: 10pt;
    text-shadow: 0px 2px 3px grey;
}

.right-value {
    text-align: right;
    font-size: 10pt;
    font-weight: bold;
    text-shadow: 0px 2px 3px grey;
}

.hvr-outline-out {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
}

.hvr-outline-out:before {
    content: '';
    position: absolute;
    border: #EA621F solid 2px;
    border-radius: 3px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-property: top, right, bottom, left;
    transition-property: top, right, bottom, left;
}

.hvr-outline-out:hover:before, .hvr-outline-out:focus:before, .hvr-outline-out:active:before {
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
}
</style>
